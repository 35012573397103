import React, { useState, Dispatch, SetStateAction } from 'react';

import { CustomFieldTypes, CustomFieldKind } from './CustomFieldTypes';
import { AddTextCustomField } from './AddTextCustomField';
import { AddYesNoToggleCustomField } from './AddYesNoToggleCustomField';
import { StepperModal } from '../StyledModal/StepperModal';
import { AddFileUploadCustomField } from './AddFileUploadCustomField';
import { AddContactCustomField } from './AddContactCustomField';
import { AddDropdownCustomField } from './AddDropdownCustomField';
import { AddMultipleChoiceCustomField } from './AddMultipleChoiceCustomField';
import { AddAdditionalQuestionCustomField } from './AddAdditionalQuestionCustomField';
import { AddCurrencyCustomField } from './AddCurrencyCustomField';
import { noop } from 'lodash/fp';

interface IProps extends CustomFieldKind {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (variables?: any) => any
}

// eslint-disable-next-line no-shadow
export enum VIEWS {
  CUSTOM_FIELD_TYPES = 'CUSTOM_FIELD_TYPES',
  TEXT = 'TEXT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  YES_NO_TOGGLE = 'YES_NO_TOGGLE',
  CONTACT = 'CONTACT',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  ADDITIONAL_QUESTION = 'ADDITIONAL_QUESTION',
  CURRENCY = 'CURRENCY'
}

const renderModalTitle = (activeView: keyof typeof VIEWS) => {
  switch (activeView) {
  case VIEWS.CUSTOM_FIELD_TYPES:
    return 'Choose Custom Field Type';
  case VIEWS.TEXT:
    return 'Add Custom Text Field';
  case VIEWS.FILE_UPLOAD:
    return 'Add Custom File Upload Field';
  case VIEWS.YES_NO_TOGGLE:
    return 'Add Custom Yes / No Toggle Field';
  case VIEWS.CONTACT:
    return 'Add Custom Contact Field';
  case VIEWS.DROPDOWN:
    return 'Add Custom Dropdown Field';
  case VIEWS.MULTIPLE_CHOICE:
    return 'Add Custom Multiple Choice Field';
  case VIEWS.ADDITIONAL_QUESTION:
    return 'Add Custom Additional Question Field';
  case VIEWS.CURRENCY:
    return 'Add Custom Currency Field';
  default:
    return 'Add Custom Field'
  }
}

export const SetActiveViewContext = React.createContext<() => void>(noop);

export const AddCustomFieldModal = ({ kind, isOpen, setIsOpen, onSuccess }: IProps) => {
  const [activeView, setActiveView] = useState<keyof typeof VIEWS>(VIEWS.CUSTOM_FIELD_TYPES);

  const returnToFirstStep = () => {
    setActiveView(VIEWS.CUSTOM_FIELD_TYPES)
  }
  const views = {
    CUSTOM_FIELD_TYPES: <CustomFieldTypes setActiveView={setActiveView}  />,
    TEXT: <AddTextCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind}/>,
    FILE_UPLOAD: <AddFileUploadCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    YES_NO_TOGGLE: <AddYesNoToggleCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    CONTACT: <AddContactCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    DROPDOWN: <AddDropdownCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    MULTIPLE_CHOICE: <AddMultipleChoiceCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    ADDITIONAL_QUESTION: <AddAdditionalQuestionCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
    CURRENCY: <AddCurrencyCustomField setIsOpen={setIsOpen} onSuccess={onSuccess} kind={kind} />,
  }

  return (
    <StepperModal
      title={renderModalTitle(activeView)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width='80vw'
      maxWidth='1200px'
      height='80vh'
      maxHeight='650px'
      afterClose={() => setActiveView(VIEWS.CUSTOM_FIELD_TYPES)}
      steps={['Choose type', 'Enter info']}
      activeStep={activeView === VIEWS.CUSTOM_FIELD_TYPES ? 0 : 1}
    >
      <SetActiveViewContext.Provider value={returnToFirstStep}>
        {views[activeView]}
      </SetActiveViewContext.Provider>
    </StepperModal>
  );
};
