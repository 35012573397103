import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, FetchResult } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  StyledContainer,
  StyledFormContainer,
  StyledForm,
  StyledFormInnerContainer
} from '../styled';
import { AddressFields } from '../AddressFields';
import { Button } from '@/components/designSystem/buttons';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { FooterContainer } from '../../StyledModal/styled';
//import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';

const IMPORT_CUSTOMER = gql`
  mutation ImportManualCustomer($manualCustomer: ImportManualCustomerInput!) {
    importManualCustomer(manualCustomer: $manualCustomer) {
      businessName
    }
  }
`;

interface IProp {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

export const ImportCustomer = ({setIsOpen, onSuccess}: IProp) => {
  const [importManualCustomer] = useMutation(IMPORT_CUSTOMER);
  const history = useHistory();

  const handleSubmit = async (args: any) => {
    const address = get('company.address', args);
    const variables = {
      manualCustomer: {
        businessName: get('businessName', args),
        contactPerson: get('contactPerson', args),
        contactEmail: get('contactEmail', args),
        contactPhone: get('contactPhone', args),
        address: JSON.stringify(address)
      }
    }
    console.log(variables, 'vars');

    importManualCustomer({variables})
      .then((response) => {
        setIsOpen(false)
        showToast({
          title: 'Customer Imported.',
          description: `${variables.manualCustomer.businessName} added to your customer list.`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
        history.push('/dashboard/customers/imported')
      })
  }

  return (
    <StyledContainer>
      <StyledFormContainer>
        <Formik onSubmit={handleSubmit}
          initialValues={{
            businessName: '',
            contactPerson: '',
            contactEmail: '',
            contactPhone: '',
            company: {
              address: {
                address_line_1: '',
                city: '',
                state: '',
                postal_code: ''
              }
            }
          }}
          validateOnChange
          enableReinitialize
        >
          <StyledForm>
            <StyledFormInnerContainer>
              <Row>
                <TextField required type='text' name='businessName' label='Business Name' placeholder='e.g., Excel Manufactoring LTD' />
                <TextField required type='text' name='contactPerson' label='Contact Person Name' placeholder='Contact Person Name' />
              </Row>
              <Row>
                <TextField required type='email' name='contactEmail' label='Email' placeholder='you@company.com' />
                <PhoneNumberField required name='contactPhone' label='Phone Number' placeholder='Phone number' />
              </Row>
              <AddressFields />
            </StyledFormInnerContainer>
            <FooterContainer>
              <Button wide primary type='submit'>Import Customer</Button>
            </FooterContainer>
          </StyledForm>
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )
}
