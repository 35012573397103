import React from 'react';

import { useFormikContext } from 'formik';

import { Button } from '@/components/designSystem/buttons';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { BodyText } from '@/components/designSystem/Typography';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';

import { FooterContainer } from '../../StyledModal/styled';
import { AddressFields } from '../AddressFields';
import { IManualCustomerFormValues } from './useForm';
import { StyledBodyText, StyledCheckboxContainer, StyledForm, StyledFormInnerContainer } from '../styled';

export const ManualCustomerForm = () => {
  const { values } = useFormikContext<IManualCustomerFormValues>();

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <Row>
          <TextField required type='text' name='company.name' label='Business Name' placeholder='e.g., Excel Manufactoring LTD' />
          <TextField required type='text' name='name' label='Contact Person Name' placeholder='Contact Person Name' />
        </Row>
        <Row>
          <TextField required type='email' name='email' label='Email' placeholder='you@company.com' />
          <PhoneNumberField required name='phoneNumber' label='Phone Number' placeholder='Phone number' />
        </Row>
        <AddressFields />
        <Row>
          <StyledCheckboxContainer>
            <FieldLabel>
              Ask your client to digitally sign your Credit Terms and Conditions and/or your Personal Guarantee.
            </FieldLabel>
            <CheckboxField name='signatureRequiredCreditTerms'>
              <BodyText>Enable Signature for Credit Terms and Conditions</BodyText>
            </CheckboxField>
            <CheckboxField name='signatureRequiredPersonalGuarantee' disabled={!values.signatureRequiredCreditTerms}>
              <BodyText>Enable Signature for Personal Guarantee</BodyText>
            </CheckboxField>
          </StyledCheckboxContainer>
        </Row>
        <StyledBodyText>Custom Message</StyledBodyText>
        <TextAreaField
          name='customMessage'
          placeholder='Please digitally sign this agreement.'
          disabled={!values.signatureRequiredCreditTerms}
        />
        <Row>
          <FileUploadField name='file' label='Attach Documents' notes='These documents will be part of your contract (if signature required) and shared with your applicant. Only upload documents received from your customer.'/>
        </Row>
      </StyledFormInnerContainer>
      <FooterContainer>
        <Button wide primary type='submit'>Add Manual Customer</Button>
      </FooterContainer>
    </StyledForm>
  )
}
