import { format, formatDistance } from 'date-fns';

import { sortBy } from 'lodash/fp';

// Be careful with date-fns versions while checking its documentation
export const formatDateToMonthYearString = (value: string) => format(new Date(value), 'MMM \'yy');
export const formatDateToShortDateString = (value: string) => format(new Date(value), `MMM d ''yy`);
export const formatDateToLongDateString = (value: string) => format(new Date(value), "MMMM do, yyyy");
export const formatDateToDateTimeString = (value: string | Date) => format(new Date(value), "yyyy-MM-dd 'at' h:mm");
export const formatDateToTimeAgo = (value: string | Date) => formatDistance(new Date(value), new Date(), { addSuffix: true });

export const orderByDate = (list: any, key: any) => sortBy((item) => new Date(item[key]), list);

export const formatDate = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
