import React from 'react';

import { format } from 'date-fns';
import { formatCurrency } from '@/utils/money';
import { get } from 'lodash/fp';

import { VR_STATUS } from '@/constants';

import { StatusLabel } from "@/components/Label/StatusLabel";

import { GreenCheckIcon, RedXIcon, YellowWarningIcon } from '../icons';

interface IProps {
  value: string,
}

export const StatusCell = ({ value }: IProps) => <StatusLabel value={value} />;

export const IconCell = ({ value }: IProps) => {
  switch (value) {
  case VR_STATUS.SUCCESS:
    return <GreenCheckIcon />;

  case VR_STATUS.FAILURE:
    return <RedXIcon />;

  default:
    return <YellowWarningIcon />;
  }
};

export const DollarCell = (data: any) => {
  const amount = get('value.amount', data);
  const currency = get('value.currency', data);
  return (amount || amount === 0 ? formatCurrency(amount, false, currency) : '');
}

export const DateCell = ({ value }: IProps) => value ? format(new Date(value), 'MMM d, y') : '';
