import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult, useMutation } from '@apollo/react-hooks';
import { CREATE_CUSTOM_FIELD } from '@/graphql/mutations/createCustomField';

import { Formik } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { CUSTOM_FIELD_TYPES } from '@/constants';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { AddFileUploadCustomFieldForm } from './form';
import { StyledContainer, StyledFormContainer } from '../styled';
import { CustomFieldKind } from '../CustomFieldTypes';

interface IProps extends CustomFieldKind {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

const addCustomFieldInitialValues = {
  label: '',
  allowMultipleUploads: false,
  description: '',
  required: false,
};

export interface IAddTextCustomFieldValues {
  label: string,
  allowMultipleUploads: boolean,
  description: string,
  required: boolean,
}

export const AddFileUploadCustomField = ({ setIsOpen, onSuccess, kind }: IProps) => {

  const [createCustomField] = useMutation(CREATE_CUSTOM_FIELD);

  const validationShape = {
    label: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    allowMultipleUploads: Yup.boolean(),
    description: Yup.string(),
    required: Yup.boolean(),
  };

  const customFieldValidation = Yup.object().shape(validationShape);

  const handleSubmit = async (data: IAddTextCustomFieldValues) => {
    const {
      label,
      description,
      required,
      allowMultipleUploads,
    } = data;
    const variables = {
      customFieldAttrs: {
        type: CUSTOM_FIELD_TYPES.FILE_UPLOAD,
        label,
        allowMultipleUploads,
        description,
        required,
        kind
      }
    };

    createCustomField({ variables })
      .then((response) => {
        showToast({
          title: 'Field Created',
          description: `Successfully created the ${label} field`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  return (
    <StyledContainer>
      <StyledFormContainer>
        <Formik
          onSubmit={handleSubmit}
          initialValues={addCustomFieldInitialValues}
          validationSchema={customFieldValidation}
          validateOnChange
          enableReinitialize
        >
          <AddFileUploadCustomFieldForm />
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )}
