import React from 'react';

import { get } from 'lodash/fp';

import { ICustomer } from '@/types/customer';

import {
  StyledTabPanelContainer,
} from '../../styled';
import {
  StyledContainer,
} from './styled';
import { Comments } from './Comments';
import { Activity } from './Activity';
import { CircularProgressBar } from '@/components/CircularProgressBar';

interface IProps {
  pinnedActivities: any[],
  activities: any[],
  customerData: ICustomer,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const Activities = ({ customerData, pinnedActivities, activities, refetchCustomerData, canSeeEditControl }: IProps) => {
  return (
    <StyledTabPanelContainer>
      <StyledContainer>
        <div style={{ flexGrow: 1 }}>
          <Comments canSeeEditControl={canSeeEditControl} customerData={customerData} refetch={refetchCustomerData} />
        </div>
        <CircularProgressBar customerData={customerData} canSeeEditControl={canSeeEditControl} refetchCustomerData={refetchCustomerData}/>
      </StyledContainer>
      <Activity pinnedActivities={pinnedActivities} activities={activities} refetch={refetchCustomerData} />
    </StyledTabPanelContainer>
  )};
