import React, {useContext} from 'react';

import { useFormikContext } from 'formik';

import { BodyText, H3 } from '@/components/designSystem/Typography';
import { TextField } from '@/components/designSystem/Form/TextField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { CheckboxField } from '@/components/designSystem/Form/CheckboxField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { IAddCurrencyCustomFieldValues } from '.';
import {
  StyledPreviewFieldContainer,
  StyledForm,
  StyledFormInnerContainer,
  StyledLeftFormContainer
} from '../styled';
import { FooterContainer,  } from '../../StyledModal/styled';
import { CustomFieldPreviewContainer } from '../CustomFieldPreviewContainer';
import { AddCustomFieldBackButton } from '../BackButton';
import { Button } from '@/components/designSystem/buttons';
import { getOr } from 'lodash/fp';

export const AddCurrencyCustomFieldForm = () => {
  const { values } = useFormikContext<IAddCurrencyCustomFieldValues>()
  const currentCompany = useContext(CurrentCompanyContext);
  const currency = getOr('USD', 'currentCompany.settings.currency', currentCompany);

  return (
    <StyledForm>
      <StyledFormInnerContainer>
        <StyledLeftFormContainer>
          <AddCustomFieldBackButton />
          <TextField required type='text' name='label' label='Label' />
          <TextAreaField name='description' label='Description' />
          <CheckboxField name='required'><BodyText>Make this field required</BodyText></CheckboxField>
        </StyledLeftFormContainer>
        <CustomFieldPreviewContainer>
          <>
            <H3>Credit Applicant will see:</H3>
            <StyledPreviewFieldContainer>
              <CurrencyField
                required={values.required}
                name='example'
                label={values.label || 'Label Example'}
                description={values.description}
                currency={currency}
              />
            </StyledPreviewFieldContainer>
          </>
        </CustomFieldPreviewContainer>
      </StyledFormInnerContainer>
      <FooterContainer>
        <Button wide primary type='submit'>Add Custom Field</Button>
      </FooterContainer>
    </StyledForm>
  )
};
