import React from 'react';

import { ADDITIONAL_QUESTION_DEFAULT_OPTION } from '@/constants';

import { IForToggleNullable } from '@/types/customField';

import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';

import { SelectField } from '../designSystem/Form/SelectField';
import { MultipleChoiceField } from '../designSystem/Form/MultipleChoiceField';
import { TextField } from '../designSystem/Form/TextField';
import { YesNoToggleField } from '../designSystem/Form/YesNoToggleField';
import { CurrencyField } from '../designSystem/Form/CurrencyField';
import { BodyText } from '@/components/designSystem/Typography';
import { ICustomFieldAnswer } from '@/types/customField';
import { ISettings } from '@/types/settings';

interface IProps {
  id: string,
  type?: string,
  label?: string,
  name?: string,
  description?: string,
  required?: boolean,
  allowMultipleUploads?: boolean | null,
  allowedFiles?: string,
  yesToggle?: IForToggleNullable,
  noToggle?: IForToggleNullable,
  options?: string[],
  selectedValue?: string,
  customFieldAnswers?: ICustomFieldAnswer[],
  settings: Partial<ISettings>
}

export const CustomField = ({
  id,
  type,
  label,
  name = id,
  description,
  required,
  allowMultipleUploads,
  allowedFiles,
  yesToggle,
  noToggle,
  options,
  selectedValue,
  settings
}: IProps) => {
  const currency = settings.currency || 'USD';
  switch (type) {
  case 'file_upload':
    return <FileUploadField required={required} name={name} label={label} description={description} onlyOne={!allowMultipleUploads} acceptFiles={allowedFiles} />;

  case 'text':
    return <TextField type='text' placeholder={`Enter ${label}`} required={required} name={name} label={label} description={description} />;

  case 'toggle':
    return yesToggle ? (
      <YesNoToggleField
        name={name}
        label={label}
        description={description}
        required={required}
        yesToggle={Object.assign({type: "text", allowMultipleUploads: false, description: ''}, yesToggle)}
        noToggle={Object.assign({type: "text", allowMultipleUploads: false, description: ''}, noToggle)}
      />
    ) : null;

  case 'dropdown':
    return (options ? (
      <SelectField
        required={required}
        name={name}
        label={label}
        description={description}
        defaultValue={selectedValue ?? undefined}
        options={options.map((option) => ({ label: option, value: option }))}
      />
    ) : <div />
    );

  case 'additional_question':
    return (options ? (
      <SelectField
        required={required}
        name={name}
        label={label}
        description={description}
        defaultValue={selectedValue ?? undefined}
        options={options.map((option) => ({ label: option, value: option })).concat([ADDITIONAL_QUESTION_DEFAULT_OPTION])}
      />
    ) : <div />
    );

  case 'multiple_choice':
    return (
      <MultipleChoiceField
        name={name}
        required={required}
        label={label}
        options={options}
        description={description}
      />
    );

  case 'currency':
    return <CurrencyField required={required} name={name} label={label} description={description} currency={currency} />;

  default:
    return null;
  }
};

export const CustomFieldAnswers = ({
  id,
  type,
  label,
  customFieldAnswers,
}: IProps) => {
  const customFieldAnswer = customFieldAnswers?.find((answer) => id === answer.customField.id)
  switch (type) {
  case 'file_upload':
    return null;

  case 'toggle':
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.text : 'Not Provided'}<br/></BodyText>;

  case 'dropdown':
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.text : 'Not Provided'}<br/></BodyText>;

  case 'multiple_choice':
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.selections.join(',') : 'Not Provided'}<br/></BodyText>;

  case 'additional_question':
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.text : 'Not Provided'}<br/></BodyText>;

  case 'currency':
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.currency.currency + '' + customFieldAnswer.currency.amount : 'Not Provided'}<br/></BodyText>;

  default:
    return <BodyText> {label} : {customFieldAnswer? customFieldAnswer.text : 'Not Provided'}<br/></BodyText>;
  }
};
