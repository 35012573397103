import gql from 'graphql-tag';

export const GET_TAG_BY_ID = gql`
  query GetTagById($tagId: ID!) {
    getTagById(id: $tagId) {
      id
      tagName
      type
      tagFrontColor
      tagBackColor
      description
    }
  }
`;

export const GET_TAGS_BY_PROVIDER_ID = gql`
  query GetTagsByProviderId($providerCompanyId: ID!) {
    getTagsByProviderId(providerCompanyId: $providerCompanyId) {
      id
      tagName
      type
      tagFrontColor
      tagBackColor
      description
    }
  }
`;

export const GET_TAGS_BY_APPLICATION_ID = gql`
  query GetTagsByApplicationId($applicationId: ID!) {
    getTagsByApplicationId(applicationId: $applicationId) {
      id
      tagId
      applicationId
    }
  }
`;
