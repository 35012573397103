import gql from 'graphql-tag';

export const GET_CURRENT_COMPANY = gql`
  query GetCurrentCompany {
    currentCompany {
      id
      name
      numRequiredReferences
      numTotalReferences
      agreementText
      personalGuaranteeText
      genericSeekerOnboardUrl
      creditTerms
      incompleteCreditApplicationCustomers {
        id
        providerCompany {
          id
          name
        }
      }
      planInfo {
        stripePlanId
        planName
        canAddVendor
        enabledReview
      }
      listUsers {
        id
        name
        email
        status
        companyName
        referralId
        invitationSentAt
        userCompanyRoles {
          isAdmin
          creditLimit
          company {
            id
          }
          role {
            name
            isCreditLimitApply
            permissions {
              inviteCustomer
              readOnly
              inviteUser
              changeTemplate
            }
          }
        }
      }
      vrCustomFields {
        id
        type
        label
        step
        name
        required
        enabled
        updatedAt
      }
      settings {
        autoBavDefault
        requestBankStatements
        requireEinFederalTaxNumber
        enablePersonalGuaranteeDefault
        requirePersonalGuaranteeDefault
        autoSendVrInvite
        updatedAt
        creditSafeUsername
        dnbApiKey
        equifaxCustomerCode
        equifaxCustomerNumber
        equifaxClientId
        experianUsername
        experianClientId
        experianSubcode
        seekerApprovalAlert
        seekerDenialAlert
        enableVrCreditSafeIncentive
        enableApideck
        enableAddress
        requireAddress
        enableAddressFor
        enableSsnSinNumber
        requireSsnSinNumber
        enableSsnSinNumberFor
        enableDriverLicenseNo
        requireDriverLicenseNo
        enableDriverLicenseNoFor
        enableDriverLicenseState
        requireDriverLicenseState
        enableDriverLicenseStateFor
        enableDateOfBirth
        requireDateOfBirth
        enableDateOfBirthFor
        requireBankInfo
        enableShippingAddress
        requireShippingAddress
        enableBillingAddress
        requireBillingAddress
        requireOwnershipStake
        enableOwnershipStake
        requireOwner
        enableOwner
        enableServiceLocations
        requireServiceLocations
        enableCreditLimit
        requireCreditLimit
        enableCreditTerms
        requireCreditTerms
        currency
      }
      brand {
        id
        logo {
          id
          path
          originalFilename
          signedUrl
        }
        primaryColor
        secondaryColor
        brandColor
        backgroundColor
      }
    }
  }
`;
