import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client'; 
import { GET_CUSTOMER_AS_PROVIDER } from '@/graphql/queries/getCustomer';

import { get, getOr } from 'lodash/fp';

import { IGetCustomerAsProviderData } from '@/types/querries';

// const ApplicationStatusContext = createContext('');

const ApplicationStatusContext = createContext({
  appStatus: '',
  triggerRefetch: () => {}
});

export const useApplicationStatus = () => useContext(ApplicationStatusContext);

interface IProps {
  children: React.ReactNode,
}

export const ApplicationStatusProvider = ({ children }: IProps) => {
  const [appStatus, setAppStatus] = useState<string>('');
  const location = useLocation();
  const idRegex = /^\/dashboard\/customers\/[a-f0-9\-]+$/;
  
  const isCustomerPage = idRegex.test(location.pathname);
  const customerId = isCustomerPage ? location.pathname.split('/').pop() : null;
  
  const { data: getCustomerAsProviderData, loading, error, refetch } = useQuery<IGetCustomerAsProviderData>(
    GET_CUSTOMER_AS_PROVIDER,
    { 
      variables: { customerId }, 
      skip: !isCustomerPage,
      fetchPolicy: 'no-cache'
    }
  );

  const formatStatus = (status: string) => {
    return status
      .replace(/_/g, ' ') 
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const mapStatusToGeneralLabel = (status: string) => {
    const statusMap: Record<string, string> = {
      created: 'In Progress',
      sent: 'In Progress',
      in_progress: 'In Progress',
      changes_requested: 'In Progress',
      agreement_processing: 'In Progress',
      agreement_viewed: 'In Progress',
      pending_approval: 'Pending Approval',
      pending_review: 'Pending Review',
      external_review: 'Pending Review',
      review_scheduled: 'Pending Review',
      approved: 'Approved',
      archived: 'Archived',
      imported: 'Imported',
      denied: 'Archived'
    };

    return statusMap[status] || formatStatus(status);
  };
  
  useEffect(() => {
    if (getCustomerAsProviderData && !loading && !error) {
      const customer = get('getCustomerAsProvider', getCustomerAsProviderData);
      const application = get('latestApplication', customer);
      const status: string = getOr('', 'providerDisplayStatus', application);

      setAppStatus(mapStatusToGeneralLabel(status));
    } else if (!isCustomerPage) {
      setAppStatus('');
    }
  }, [getCustomerAsProviderData, loading, error, isCustomerPage]);

  // Refetch function to be exposed via the context
  const triggerRefetch = useCallback(() => {
    if (isCustomerPage) {
      refetch();  // Trigger refetch of application status
    }
  }, [isCustomerPage, refetch]);

  return (
    <ApplicationStatusContext.Provider value={{appStatus, triggerRefetch}}>
      {children}
    </ApplicationStatusContext.Provider>
  );
};
