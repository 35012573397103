import React, { useContext } from 'react';

import { IVRCustomField } from '@/types/vrCustomField';

import { TextField } from '@/components/designSystem/Form/TextField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { GoogleAddressField } from '@/components/GoogleAddressField';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';
import { get } from 'lodash/fp';
import { setFieldsFromGooglePlace } from './functions';

interface IProps extends IVRCustomField {
  setFieldValue: (field: string, value: string | null) => void,
}

export const VRCustomField = ({ name, type, label, required, setFieldValue }: IProps) => {
  const currentCompany = useContext(CurrentCompanyContext);
  const currency = get('currentCompany.settings.currency', currentCompany);
  if (name === 'address') {
    return (
      <GoogleAddressField
        required={required}
        type='text'
        name={name}
        label={label}
        setFieldsFromGooglePlace={
          (place) => setFieldsFromGooglePlace(place, name, setFieldValue)
        }
      />
    )
  }
  if (type === 'number') {
    return (
      <CurrencyField required={required} name={name} label={label} currency={currency} />
    )
  }
  return (
    <TextField required={required} type='text' name={name} label={label} placeholder='' />
  )
}
