import React, { Dispatch, SetStateAction, useContext } from 'react';

import { get } from 'lodash/fp';

import { IVendor } from '@/types/vendor';

import { CREDIT_TERMS } from '@/constants';
import { UPDATE, CREATE } from '../constants';

import { Flex } from '@/components/designSystem/Layout';
import { Row } from '@/components/designSystem/Form/styled';
import { TextField } from '@/components/designSystem/Form/TextField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { Button } from '@/components/designSystem/buttons';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { StyledButtonsContainer, StyledAutoFitColumnsRow } from '../../../styled';
import { IShownVendorCustomFields } from './Reference';
import { UploadedDocuments } from './UploadedDocuments';
import { EmailThreads } from './EmailThreads';

interface IProps {
  reference: IVendor,
  refetch: () => void,
  shownVendorCustomFields: IShownVendorCustomFields,
  setSubmitFrom: Dispatch<SetStateAction<string | null>>,
  isEditActive: boolean,
  setEditActive: Dispatch<SetStateAction<boolean>>,
}

export const ReferenceUpdateView = ({
  reference,
  refetch,
  shownVendorCustomFields,
  setSubmitFrom,
  isEditActive,
  setEditActive,
}: IProps) => {
  const referenceId = get('id', reference);
  const emailThreads = get('emailThreads', reference);

  const currentCompany = useContext(CurrentCompanyContext);
  const currency = get('currentCompany.settings.currency', currentCompany);

  return (
    <>
      <StyledAutoFitColumnsRow>
        <DatePickerField name='customerSince' label='Customer Since' />
        <CurrencyField name='creditLimit' label='Credit Limit' currency={currency} />
        <SelectField creatable name='creditTerms' label='Terms' options={CREDIT_TERMS} />
        <TextField type='number' name='averageDaysToPay' label='Average Days To Pay' />
      </StyledAutoFitColumnsRow>
      <StyledAutoFitColumnsRow>
        <DatePickerField name='lastPaymentOn' label='Last Payment Date' />
        <CurrencyField name='lastPaymentAmount' label='Last Payment Amount' currency={currency} />
        <CurrencyField name='creditBalancePastDue' label='Past Due Balance' currency={currency} />
        <CurrencyField name='creditBalanceHigh' label='High Credit Balance' currency={currency} />
      </StyledAutoFitColumnsRow>
      {Object.values(shownVendorCustomFields).includes(true) ? (
        <>
          <StyledAutoFitColumnsRow>
            {shownVendorCustomFields.address && <TextField type='text' name='address' label='Address' />}
            {shownVendorCustomFields.website && <TextField type='text' name='websiteAddress' label='Website Address' />}
            {shownVendorCustomFields.accountNumber && <TextField type='text' name='accountNumber' label='Account Number' />}
            {shownVendorCustomFields.currentBalance && <CurrencyField name='currentBalance' label='Current Balance' currency={currency} />}
          </StyledAutoFitColumnsRow>
        </>
      ) : false}
      <Row>
        <TextAreaField name='comments' label='Notes' placeholder='Include comments...' />
      </Row>
      <UploadedDocuments reference={reference} isEditActive={isEditActive} refetch={refetch} />
      <EmailThreads emailThreads={emailThreads} reference={reference} refetch={refetch}/>
      <StyledButtonsContainer>
        <Button onClick={() => setEditActive(false)} secondary>Cancel</Button>
        {referenceId
          ? <Button type='submit' primary onClick={() => setSubmitFrom(UPDATE)}>Save</Button>
          : <Button type='submit' primary onClick={() => setSubmitFrom(CREATE)}>Submit</Button>
        }
      </StyledButtonsContainer>
    </>
  )}
