import React, { useState, useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { FileUpload, IFile } from '@/components/FileUpload/FileUpload';
import { Row } from '@/components/designSystem/Form/styled';
import { StyledDescriptionText, StyledNoteText, StyledErrorText, StyledFieldContainer } from './styled';
import { FieldLabel } from './FieldLabel';

interface IProps {
  name: string,
  label?: string,
  required?: boolean,
  description?: string,
  notes?: string,
  onlyOne?: boolean | null,
  prompt?: string,
  acceptFiles?: string,
}

export const FileUploadField = ({
  required,
  name = '',
  label,
  description,
  notes,
  onlyOne,
  prompt,
  acceptFiles,
}: IProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const [visibleFiles, setVisibleFiles] = useState(field.value);

  const showError = !!(meta.touched && meta.error);
  
  useEffect(() => {
    if (showError) {
      showToast({
        title: 'Required Information Missing',
        description: 'Please upload the requied file(s)',
        type: toast.TYPE.ERROR,
      });
    }
  }, [showError]);

  const setFileFieldState = (files: IFile[]) => {
    setVisibleFiles(files);
    const uploadedFiles = files.filter((x) => x.signedUrl);
    setValue(uploadedFiles);
  };
  
  const onDropRejected = (rejectedFiles: any) => {
    showToast({
      title: 'File Type Error',
      description: `The following files are not accepted: ${rejectedFiles.map((file: any) => file.file.name).join(', ')}`,
      type: toast.TYPE.ERROR,
    });
  };

  return (
    <StyledFieldContainer>
      <Row>
        {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
        {notes ? <StyledNoteText>{notes}</StyledNoteText> : false}
      </Row>
      <FileUpload
        allFiles={visibleFiles}
        setAllFiles={setFileFieldState}
        onlyOne={onlyOne}
        error={showError}
        prompt={prompt}
        accept={acceptFiles}
        onDropRejected={onDropRejected}
      />
      {description ? <StyledDescriptionText>{description}</StyledDescriptionText> : false}
      <StyledErrorText>
        { (showError && name) ? <ErrorMessage name={name} /> : false }
      </StyledErrorText>
    </StyledFieldContainer>
  )
};
