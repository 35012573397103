import gql from 'graphql-tag';

export const DELETE_TAG = gql`
  mutation DeleteTag($tagId: ID!) {
    deleteTag(tagId: $tagId) {
      id
    }
  }
`;

export const DELETE_APPLICATION_TAG = gql`
  mutation DeleteApplicationTag($tagId: ID!, $applicationId: ID!) {
    deleteApplicationTag(tagId: $tagId, applicationId: $applicationId) {
      id
    }
  }
`;