import { get } from "lodash/fp";

import { IApplication } from '@/types/application';
import { CHANGES_REQUESTED_SECTIONS } from "@/constants";

export const getActionsInitialValues = (application: IApplication, seekerDenialAlert: boolean, seekerApprovalAlert: boolean) => {
  const creditLimit = get('creditLimit', application);
  const creditLimitCurrency = get('creditLimitCurrency', application);
  const creditTerms = get('creditTerms', application);
  const status = get('status', application);
  const providerComment = get('providerComment', application) || '';

  return {
    creditLimit,
    creditLimitCurrency,
    creditTerms,
    status,
    providerComment,
    seekerDenialAlertEmail: seekerDenialAlert,
    seekerApprovalAlertEmail: seekerApprovalAlert,
  }
};
