import { getOr } from 'lodash/fp';
const formatter = (currencyCode: string, decimals=true) => {
  const defaultOptions = {
    style: 'currency',
    currency: currencyCode
  }

  const options = decimals ? defaultOptions : { ...defaultOptions, maximumFractionDigits: 0};

  return new Intl.NumberFormat('en-US', options);
}

export const getCurrencySymbol = (currencyCode : string) =>  {
  const part = formatter(currencyCode).formatToParts(1).find((item) => item.type == 'currency');
  return getOr('USD', 'value', part);
}

export const formatCurrency = (value: number, decimals = true, currency = 'USD') => {
  if (!value && value !== 0) return '';

  return formatter(currency, decimals).format(value);
};

export const CURRENCY_OPTIONS = [
    {label: 'US dollar', value: 'USD'},
    {label: 'Canadian dollar', value: 'CAD'},
    {label: 'Euro', value: 'EUR'},
    {label: 'British pound', value: 'GBP'}
  ]

