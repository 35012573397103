import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { SEND_MANUAL_CUSTOMER_FOLLOW_UP } from '@/graphql/mutations/sendManualFollowUp';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { get, getOr } from 'lodash/fp';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2rem;
`;

const SVGContainer = styled.div`
  position: relative;
`;

const SVG = styled.svg`
  width: 100%;
  height: auto;
`;

const BackgroundCircle = styled.circle`
  fill: transparent;
  stroke: hsla(225, 20%, 92%, 0.9);
  stroke-linecap: round;
`;

const ProgressCircle = styled.circle`
  fill: transparent;
  stroke: #047579;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease-out; // Adjust transition duration as needed
  transform: rotate(-90deg);
  transform-origin: center;
`;

const TextOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsla(225, 23%, 62%, 1);
  font-weight: bold;
  // z-index: 10;
`;

const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StepName = styled.p`
  font-size: 1rem;
  font-weight: 600;
`;

const StyledLink = styled.a`
  color: #047579;
  text-decoration: underline;
  font-size: 0.875rem;
  cursor: pointer;
`;
import { ICustomer } from '@/types/customer';
interface CircularProgressBarProps {
  customerData: ICustomer,
  canSeeEditControl: boolean,
  refetchCustomerData: () => void,
  label?: string;
  width?: number;
}

export const CircularProgressBar = ({
  customerData,
  canSeeEditControl,
  refetchCustomerData,
  label = 'Progress Bar',
  width = 100
}: CircularProgressBarProps) => {
  const stepName = (customerData.onboardStep || '').toString();
  const onboardComplete = getOr(false, 'onboardComplete', customerData);
  const isManualCustomer = getOr(false, 'isManualCustomer', customerData);
  const customerId = getOr(false, 'id', customerData);

  let currentStepInfo;
  switch(stepName) {
  case 'company':
    currentStepInfo = {currentStep: 2 , currentStepName: 'Company Profile'};
    break;
  case 'stakeholder':
    currentStepInfo = {currentStep: 3 , currentStepName: 'Company Contacts'}
    break;
  case 'bank':
    currentStepInfo = {currentStep: 4 , currentStepName: 'Financial Health'}
    break;
  case 'vendors':
    currentStepInfo = {currentStep: 5 , currentStepName: 'Vendors'}
    break;
  case 'other':
    currentStepInfo = {currentStep: 6 , currentStepName: 'Other Info'}
    break;
  case 'others':
    currentStepInfo ={currentStep: 6 , currentStepName: 'Other Info'}
    break;
  case 'agreement':
    if (onboardComplete) {
      currentStepInfo = {currentStep: 7 , currentStepName: 'Completed'}
    }else{
      currentStepInfo = {currentStep: 7 , currentStepName: 'Agreement'}
    }
    break;
  default:
    currentStepInfo = {currentStep: 1 , currentStepName: 'Basic Info'}
  }

  const [animatedStep, setAnimatedStep] = useState<number>(0);
  const totalStep = 7
  const strokeWidth = 6;
  const radius = 50 - strokeWidth;
  const circumference = radius * 2 * Math.PI;
  const targetProgressOffset = currentStepInfo.currentStep / totalStep;

  useEffect(() => {
    const duration = 1000; // Animation duration in milliseconds
    const frameRate = 60; // Frames per second
    const totalFrames = (duration / 1000) * frameRate;
    const startOffset = animatedStep / totalStep;
    const endOffset = targetProgressOffset;
    const progressPerFrame = (endOffset - startOffset) / totalFrames;

    let frame = 0;

    const animate = () => {
      frame += 1;
      const progress = startOffset + progressPerFrame * frame;
      setAnimatedStep(Math.round(progress * totalStep));

      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      }
    };

    animate();
  }, [currentStepInfo.currentStep, totalStep]);

  const progressOffset = animatedStep / totalStep;
  const strokeDashoffset = circumference * (1 - progressOffset);

  const [sendFollowup, { loading }] = useMutation(
    SEND_MANUAL_CUSTOMER_FOLLOW_UP,
    {
      variables: { customerId },
      onCompleted: () => {
        showToast({
          title: 'Follow Up Sending',
          description: `Your follow up has been sent to ${get('seekerCompany.name', customerData)}.`,
          type: toast.TYPE.SUCCESS,
        });
        refetchCustomerData();
      },
    },
  );

  return (
    <Container>
      <SVGContainer>
        <SVG
          aria-label={label}
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={progressOffset * 100}
          height={width}
          role="progressbar"
          width={width}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <BackgroundCircle
            cx="50"
            cy="50"
            r={radius}
            strokeWidth={strokeWidth}
          />
          <ProgressCircle
            cx="50"
            cy="50"
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={strokeDashoffset}
          />
        </SVG>
        <TextOverlay>
          {animatedStep} of {totalStep}
        </TextOverlay>
      </SVGContainer>
      <StepInfo>
        {!onboardComplete? <p>Currently at</p> : <p>Application</p>}
        <StepName>{currentStepInfo.currentStepName}</StepName>
        {!onboardComplete && canSeeEditControl && !isManualCustomer && (
          <StyledLink onClick={() => sendFollowup()}>
            Send Reminder
          </StyledLink>)
        }
      </StepInfo>
    </Container>
  );
};