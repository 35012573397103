import gql from 'graphql-tag';

export const CREATE_TAG = gql`
  mutation CreateTag($tag_input: TagInput!) {
    createTag(tag_input: $tag_input) {
      id
    }
  }
`;

export const CREATE_APPLICATION_TAG = gql`
  mutation CreateApplicationTag($tagId: id!, $applicationId: id!) {
    createApplicationTag(tagId: $tagId, applicationId: $applicationId) {
      id
    }
  }
`;