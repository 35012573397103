import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { UNBLOCK_REFERRALS } from '@/graphql/mutations/unblockReferrals';

import { get, getOr } from 'lodash/fp';
import { formatDateToTimeAgo } from '@/utils/date';

import { IVendor } from '@/types/vendor';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { Button } from '@/components/designSystem/buttons';

import { ReactComponent as EmailIcon } from '@/assets/email.svg';

import { StyledIconWrapper, CaptionWithIcon } from '.././styled';
import { GreenCheckIcon, RedXIcon } from '@/components/icons';
import { WarningPopupIcon } from '@/components/WarningPopupIcon';

export const useReferenceStatusInfo = (reference: IVendor, refetch: () => void, isApplicationInProgress: boolean, canSeeEditControl: boolean) => {
  const sent = getOr(false, `sourceCompany.sent`, reference);
  const inviteId = get(`sourceCompany.inviteId`, reference);
  const tempUpdatedAt = get(`updatedAt`, reference) || get(`sourceCompany.updatedAt`, reference);

  const [isSent, setIsSent] = useState(sent);
  const [updatedAt, setUpdatedAt] = useState(tempUpdatedAt);

  const [unblockReferrals] = useMutation(UNBLOCK_REFERRALS);

  const id = get('sourceUser', reference) ? get('id', reference) : get(`sourceCompany.id`, reference);
  const isComplete = !!id;

  const bounced = get(`sourceCompany.bounced`, reference);

  if (isApplicationInProgress) {
    return null;
  }

  if (!isComplete && !isSent) {
    return canSeeEditControl
      ? (
        <Button
          secondary
          small
          LeftIcon={EmailIcon}
          onClick={() => {
            unblockReferrals({ variables: { referralIds: [inviteId] } })
              .then(() => {
                showToast({
                  title: 'Request Sent',
                  description: 'Vendor Reference Request have been successfully Sent',
                  type: toast.TYPE.SUCCESS,
                });
                refetch();
                setIsSent(true);
                const now = `${new Date().toISOString().split('.')[0]}Z`; // trim the millisecond
                setUpdatedAt(now);
              });
          }}
        >
        Send Request
        </Button>
      ) : false;
  }

  return (
    <CaptionWithIcon
      color='secondary'>{
        isComplete
          ? <><StyledIconWrapper><GreenCheckIcon /></StyledIconWrapper> Updated {formatDateToTimeAgo(updatedAt)}</>
          : <>
            {
              bounced ?
                <><StyledIconWrapper><RedXIcon /></StyledIconWrapper> Email undeliverable: attempted {formatDateToTimeAgo(updatedAt)}</>
                :
                <><StyledIconWrapper><WarningPopupIcon /></StyledIconWrapper> Reference Requested {formatDateToTimeAgo(updatedAt)}</>
            }
          </>
      }
    </CaptionWithIcon>
  );
}