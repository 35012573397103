import React, { useContext } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPSERT_CONTENT } from '@/graphql/mutations/upsertContent';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { Formik, Form } from 'formik';
import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IUserCompanyRole } from '@/types/companyUser';
import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BorderedContainer } from '@/containers/BorderedContainer';

import { TextField } from '@/components/designSystem/Form/TextField';
import { H3 } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';
import Tiptap from '@/components/designSystem/Form/Editor/Tiptap';

import { StyledOrientationContainer, StyledOrientationDirection, StyledOrientationH4 } from './styled';

interface IOrientationText {
  leftTitle: string,
  leftBody: string,
  rightTitle: string,
  refetchAll: () => void,
}

export const OrientationSettingsForm = ({leftTitle: lefttitle, leftBody: leftbody, rightTitle: righttitle, refetchAll}: IOrientationText) => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const fields = {
    leftTitle: lefttitle,
    leftBody: leftbody,
    rightTitle: righttitle,
    refetchAll
  }

  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  const currentCompanyLoading = get('loading', currentCompanyData);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData)
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId),
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId)
  );
  const changeTemplate = get('role.permissions.changeTemplate', currentUserCompanyRole[0]) || false;

  const [updateCompanyOrientation] = useMutation(UPSERT_CONTENT);

  if (currentCompanyLoading) {
    return <LoadingSpinner />;
  }

  const handleSubmit = async (data: IOrientationText) => {
    const {
      leftTitle,
      rightTitle,
      leftBody,
    } = data;

    const variablesLeft = {
      content: {
        name: 'OrientationLeft',
        title: leftTitle,
        body: leftBody
      }
    };

    const variablesRight = {
      content: {
        name: 'OrientationRight',
        title: rightTitle
      }
    };

    await updateCompanyOrientation({ variables: variablesLeft })
    await updateCompanyOrientation({ variables: variablesRight })
      .then(() => {
        showToast({
          title: 'Orientation Text Updated',
          description: `Successfully Updated the Orientation Text`,
          type: toast.TYPE.SUCCESS,
        });
        refetchAll();
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Information wasn\'t updated',
          type: toast.TYPE.ERROR,
        });
      })
    //   .finally(() => {
    //   });
  };

  return (
    <Formik
      initialValues={fields}
      onSubmit={handleSubmit}
    >
      {changeTemplate &&
        <Form>
          <BorderedContainer disableDivider>
            <Flex align='center'>
              <H3 bold>Orientation Page Settings</H3>
            </Flex>
            <StyledOrientationContainer>
              <StyledOrientationDirection>
                <StyledOrientationH4 bold>Left Section Settings</StyledOrientationH4>
                <TextField type='text' name='leftTitle' label='Title' placeholder='Title' />
                <Tiptap
                  name='leftBody'
                />
              </StyledOrientationDirection>
              <StyledOrientationDirection>
                <StyledOrientationH4 bold>Right Section Settings</StyledOrientationH4>
                <TextField type='text' name='rightTitle' label='Title' placeholder='Title' />
              </StyledOrientationDirection>
            </StyledOrientationContainer>
            <Flex justify='end' align='center'>
              <Button primary type='submit'>Save</Button>
            </Flex>
          </BorderedContainer>
        </Form>
      }
    </Formik>
  )
}