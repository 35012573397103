import styled from 'styled-components';

import device from '@/styles/device';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 264px minmax(0, 1fr);
  grid-template-areas:
    "nav main";
  height: 100vh;

  @media ${device.laptopL} {
    grid-template-columns: 250px minmax(0, 1fr);
  };

  @media ${device.laptop} {
    grid-template-columns: 74px minmax(0, 1fr);
  };

  @media ${device.mobileXL} {
    grid-template-areas:
    "nav nav"
    "main main";
    grid-template-rows: 3.5rem auto;
  };
`;

export const Main = styled.main`
  grid-area: main;
  background-color: transparent;
  height: 100%;
  // overflow-y: scroll;
`;

export const Content = styled.div`
  display: flex;
  border-radius: 1.25rem;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${({ theme }) => theme.color.shadow.shadowLight};
  min-height: 100%;
  padding: 2.5rem 1.5rem 5rem;
  overflow: hidden;
`;
