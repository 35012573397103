import React from 'react';
import { CountryCode } from 'libphonenumber-js';

import { get } from 'lodash/fp';

import { formatPhone } from '@/utils/format';

import { IAddress } from '@/types/address';

import { H4, BodyText } from '@/components/designSystem/Typography';
import { Label } from '@/components/Label/Label';
import { InitialAvatar } from '@/components/InitialAvatar';

import {
  SearchResultItemContainer, Row, SearchResultContent, RightChevron,
} from './styled';

interface IProps {
  name?: string,
  address?: string | IAddress,
  safeNo?: string,
  phoneNumbers?: string,
  country?: CountryCode,
  isFirst: boolean,
  onClick?: () => void,
}

export const SearchResultItem = ({
  name, address, safeNo, phoneNumbers, country, onClick, isFirst = false,
}: IProps) => {
  const simpleAddress = get('simpleValue', address);
  const title = safeNo ? `${name} (No. ${safeNo})` : name;
  const phoneNumber = get('[0]', phoneNumbers);

  return (
    <SearchResultItemContainer onClick={onClick}>
      <Row>
        {name &&
          <InitialAvatar size={3} style={{ marginRight: '1rem'}} name={name} />
        }
        <SearchResultContent>
          <Row>
            <H4 bold>{title}</H4>
            {isFirst && <Label type='success'>Best Match ✨</Label>}
          </Row>
          <BodyText color='secondary'>{simpleAddress}</BodyText>
          {phoneNumber && <BodyText color='secondary'>{formatPhone(phoneNumber, country)}</BodyText>}
        </SearchResultContent>
      </Row>
      <RightChevron />
    </SearchResultItemContainer>
  );
};
