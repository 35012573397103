import React, { Dispatch, SetStateAction } from 'react';

import { FetchResult, useMutation } from '@apollo/react-hooks';
import { CREATE_CUSTOM_FIELD } from '@/graphql/mutations/createCustomField';

import { Formik } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { extractGQLErrorMessage } from '@/utils/extractGQLErrorMessage';

import { CUSTOM_FIELD_TYPES } from '@/constants';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { AddContactCustomFieldForm } from './form';
import { StyledContainer, StyledFormContainer } from '../styled';
import { CustomFieldKind } from '../CustomFieldTypes';

interface IProps extends CustomFieldKind {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

const addCustomFieldInitialValues = {
  label: '',
  description: '',
  contact: {
    nameRequired: false,
    emailRequired: false,
    phoneRequired: false,
    positionRequired: false
  }
};

export interface IAddContactCustomFieldValues {
  label: string,
  contact: {
    nameRequired: boolean,
    emailRequired: boolean,
    phoneRequired: boolean,
    positionRequired: boolean,
  },
}

export const AddContactCustomField = ({ setIsOpen, onSuccess, kind }: IProps) => {

  const [createCustomField] = useMutation(CREATE_CUSTOM_FIELD);

  const validationShape = {
    label: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
    description: Yup.string(),
    contact: Yup.object().shape({
      nameRequired: Yup.boolean(),
      emailRequired: Yup.boolean(),
      phoneRequired: Yup.boolean(),
      positionRequired: Yup.boolean(),
    })
  };

  const customFieldValidation = Yup.object().shape(validationShape);

  const handleSubmit = async (data: IAddContactCustomFieldValues) => {
    const {
      label,
      contact,
    } = data;
    const variables = {
      customFieldAttrs: {
        type: CUSTOM_FIELD_TYPES.CONTACT,
        label,
        contact: {
          nameRequired: contact.nameRequired,
          emailRequired: contact.emailRequired,
          phoneRequired: contact.phoneRequired,
          positionRequired: contact.positionRequired,
          // Change API to not require these permanent properties
          showName: true,
          showEmail: true,
          showPhone: true,
          showExtension: true,
          extensionRequired: false,
        },
        kind
      }
    };

    createCustomField({ variables })
      .then((response) => {
        showToast({
          title: 'Field Created',
          description: `Successfully created the ${label} field`,
          type: toast.TYPE.SUCCESS,
        });
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: extractGQLErrorMessage(error),
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  return (
    <StyledContainer>
      <StyledFormContainer>
        <Formik
          onSubmit={handleSubmit}
          initialValues={addCustomFieldInitialValues}
          validationSchema={customFieldValidation}
          validateOnChange
          enableReinitialize
        >
          <AddContactCustomFieldForm />
        </Formik>
      </StyledFormContainer>
    </StyledContainer>
  )}
