import React, { useState } from 'react';
import { addMonths } from 'date-fns';

import { useMutation } from '@apollo/react-hooks';

import { get } from 'lodash/fp';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { formatDate } from '@/utils/date';

import { H4 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';
import { StyledGridContainer, StyledGridColumn } from './styled';
import { Formik, Form, Field, useField } from 'formik';
import { Button } from '@/components/designSystem/buttons';
import { StyledFormButtonContainer } from '../styled';
import { CREATE_SCHEDULE_REVIEW } from './queries';
import { StyledSelect } from '@/components/designSystem/Form/styled';
import { DatePickerField } from '@/components/designSystem/Form/DatePickerField';
import { FieldLabel } from '@/components/designSystem/Form/FieldLabel';
import { ILabelValue } from '@/types/field';
import { ICustomer } from '@/types/customer';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';

interface IProps {
  customerData: ICustomer,
  customerId: string,
  setOpen: (isOpen: boolean) => void,
  refetchCustomerData: () => void,
  creditTermsOptions: string[]
}

type ScheduleOption = {
  label: string;
  value: string;
};

export const ScheduleReviewForm = ({customerData, customerId, refetchCustomerData, setOpen} : IProps) => {
  const [createScheduleReview] = useMutation(CREATE_SCHEDULE_REVIEW);
  const initialValues = {
    customerId: customerId,
    scheduleDate: customerData.scheduleDate || '',
    notes: ''
  }

  const handleSubmit = async (values : any) => {
    const variables = {
      customerId: customerId,
      scheduleDate:  formatDate(get('scheduleDate', values)),
      notes: get('notes', values)
    }
    createScheduleReview({variables})
      .then(() => {
        showToast({
          title: 'Schedule Review',
          description: 'Successfully created an schedule review',
          type: toast.TYPE.SUCCESS,
        });
        setOpen(false)
        refetchCustomerData()
      })
  }

  const PresetDateField = (props: any) => {
    const [ field, _meta, helpers ] = useField<Date>(props);
    const [custom, setCustom] = useState<boolean>(false);

    const scheduleOptions: ScheduleOption[] = [
      { label: 'In 1 Month', value: addMonths(new Date(), 1).toISOString() },
      { label: 'In 3 Months', value: addMonths(new Date(), 3).toISOString() },
      { label: 'In 6 Months', value: addMonths(new Date(), 6).toISOString() },
      { label: 'In 1 Year', value: addMonths(new Date(), 12).toISOString() },
      { label: 'Custom', value: 'custom'},
    ]

    const formatDateString = (value: string) => {
      if (value !== 'custom') {
        return new Date(value).toLocaleDateString('en-US');
      }
      return '';
    };

    const formattedOptions = scheduleOptions.map(option => ({
      label: option.value !== 'custom' ? `${option.label} (${formatDateString(option.value)})` : option.label,
      value: option.value,
    }));

    const { setValue } = helpers;
    return (
      <StyledGridColumn column={2}>
        <FieldLabel name={props.name} required>Schedule</FieldLabel>
        <StyledSelect
          options={formattedOptions}
          onChange={(option: ILabelValue) => {
            if(option.value == 'custom') {
              setCustom(true);
            } else {
              setCustom(false);
              setValue(new Date(option.value))
            }
          }}
        />
      {custom || field.value ?
          <DatePickerField
            name={field.name}
            label='Select Date'
            portal={true}
            minDate={new Date()}
          />
      :
        <Field type='hidden' name={field.name} />
      }
      </StyledGridColumn>
    )
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Form>
        <StyledGridContainer>
          <PresetDateField name='scheduleDate' />
          <StyledGridColumn column={2}>
            <Flex direction='column'>
              <H4 bold>Notes</H4>
              <TextAreaField name='notes' />
            </Flex>
          </StyledGridColumn>
        </StyledGridContainer>
        <StyledFormButtonContainer>
          <Button type='submit' primary wide>Submit</Button>
        </StyledFormButtonContainer>
      </Form>
    </Formik>
  )
};
