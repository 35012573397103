import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbWrapper = styled.nav`
  margin-bottom: 1rem;
`;

const BreadcrumbList = styled.ol`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  list-style: none;
  margin: 0rem;
  padding: 0rem;
`;

const BreadcrumbSeparator = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  color: #161616;
`;

const BreadcrumbText = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #161616;
`

const BreadcrumbLink = styled.a<{ isCurrent?: boolean }>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ isCurrent }) => (isCurrent ? '#047579' : '#161616')};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const breadcrumbNameMap: Record<string, string> = {
  '/dashboard/customers': 'Applications',
  '/dashboard/customers/pending-approval': 'Pending Apporval',
  '/dashboard/customers/pending-review': 'Pending Review',
  '/dashboard/customers/in-progress': 'In Progress',
  '/dashboard/customers/approved': 'Approved',
  '/dashboard/customers/archived': 'Archived',
  '/dashboard/customers/imported': 'Imported',
};

interface BreadcrumbProps {
  idName?: string;
  status?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ status, idName }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x: string) => x);

  const adjustedPathnames = pathnames.map((_, index) =>
    `/${pathnames.slice(0, index + 1).join('/')}`
  );

  const formatStatus = (status: string) => {
    return status
      .replace(/_/g, ' ') 
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const mapStatusToGeneralLabel = (status: string) => {
    const statusMap: Record<string, string> = {
      created: 'In Progress',
      sent: 'In Progress',
      in_progress: 'In Progress',
      changes_requested: 'In Progress',
      agreement_processing: 'In Progress',
      agreement_viewed: 'In Progress',
      pending_approval: 'Pending Approval',
      pending_review: 'Pending Review',
      external_review: 'Pending Review',
      review_scheduled: 'Pending Review',
      approved: 'Approved',
      archived: 'Archived',
      imported: 'Imported',
      denied: 'Archived'
    };
    
    return statusMap[status] || formatStatus(status);
  };

  const findUrlByStatus = (status: string) => {
    for (const [path, label] of Object.entries(breadcrumbNameMap)) {
      if (label.toLowerCase() === mapStatusToGeneralLabel(status).toLowerCase()) {
        return path;
      }
    }
    return '/';
  };

  

  const getCustomersLabel = (currentPath: string) => {
    const group1 = [
      '/dashboard/customers/pending-approval',
      '/dashboard/customers/in-progress',
      '/dashboard/customers/archived',
    ];

    const group2 = [
      '/dashboard/customers/pending-review',
      '/dashboard/customers/approved',
      '/dashboard/customers/imported',
    ];

    if (group1.some((path) => currentPath.startsWith(path))) {
      return 'Applications';
    }

    if (group2.some((path) => currentPath.startsWith(path))) {
      return 'Customers';
    }

    return 'Applications';
  };

  return (
    <BreadcrumbWrapper aria-label="breadcrumb">
      <BreadcrumbList>
        {adjustedPathnames.map((to: string, index: number) => {
          const isLast = index === adjustedPathnames.length - 1;

          if (to === '/dashboard') {
            return null;
          }
          
          if (to === '/dashboard/customers') {
            return (
              <li key={`customers-${index}`}>
                <BreadcrumbText>{getCustomersLabel(location.pathname)}</BreadcrumbText>
                {!isLast && <BreadcrumbSeparator>&gt;</BreadcrumbSeparator>}
              </li>
            );
          }

          if (isLast && status && idName) {
            const statusUrl = findUrlByStatus(status);
            return (
              <React.Fragment key={`last-breadcrumb-${index}`}>
                <li key={`${to}-status`}>
                  <BreadcrumbLink href={statusUrl}>
                    {mapStatusToGeneralLabel(status)}
                  </BreadcrumbLink>
                  <BreadcrumbSeparator>&gt;</BreadcrumbSeparator>
                </li>
                <li key={`${to}-idName`}>
                  <BreadcrumbLink href={to} isCurrent={isLast}>
                    {idName}
                  </BreadcrumbLink>
                </li>
              </React.Fragment>
            );
          }

          if (idName && isLast) {
            return (
              <li key={to}>
                <BreadcrumbLink href={to} isCurrent={isLast}>
                  {idName}
                </BreadcrumbLink>
              </li>
            );
          }

          return (
            <li key={to}>
              <BreadcrumbLink href={to} isCurrent={isLast} aria-current={isLast ? 'page' : undefined}>
                {breadcrumbNameMap[to] || pathnames[index]}
              </BreadcrumbLink>
              {!isLast && <BreadcrumbSeparator>&gt;</BreadcrumbSeparator>}
            </li>
          );
        })}
      </BreadcrumbList>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;