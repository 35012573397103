import React from 'react';

import { formatAddress } from '@/utils/format';
import { formatDateToLongDateString } from '@/utils/date';

import { IStakeholder } from '@/types/stakeholder';

import { Card } from '@/components/designSystem/cards/Card';
import { BodyText, H3 } from '@/components/designSystem/Typography';
import { Flex } from '@/components/designSystem/Layout';

import { ReactComponent as EmailIcon } from '@/assets/email_contact.svg';
import { ReactComponent as PhoneIcon } from '@/assets/phone_contact.svg';
import { ReactComponent as LocationIcon } from '@/assets/location.svg';
import { ReactComponent as ClipboardIcon } from '@/assets/clipboard.svg';

import { StyledIconLabel } from '../styled';
import { StyledContactInfo, StyledContactEmail } from './styled';
import { WARNINGS } from '@/constants';
import { WarningPopupIcon } from '@/components/WarningPopupIcon';

interface IProps {
  contact: IStakeholder,
}

export const Contact = ({ contact }: IProps) => {
  const address = (contact?.address)? formatAddress(contact?.address) : null
  return (
    <Card>
      <Flex justify='space-between'>
        <H3 bold>{contact.customFieldId ? contact.label : contact.position}</H3>
        {contact.isPrimaryContact ? (
          <Flex gap='0.5rem'>
            <ClipboardIcon />
            <BodyText>Signer</BodyText>
          </Flex>
        ) : false}
      </Flex>
      <StyledContactInfo>
        <BodyText>{contact.name}</BodyText>
        {(contact.email) ? (
          <StyledIconLabel>
            <EmailIcon />
            <StyledContactEmail href={`mailto:${contact.email}`}>{contact.email}</StyledContactEmail>
            {(contact.showNonBusinessEmailDomainWarning) ? (
              <WarningPopupIcon style={{ marginLeft: '0.25rem'}} description={WARNINGS.DOMAIN} />
            ) : ''}
          </StyledIconLabel>
        ) : false }
        {(contact.phoneNumber) ? (
          <StyledIconLabel>
            <PhoneIcon />
            <BodyText>{contact.phoneNumber}</BodyText>
          </StyledIconLabel>
        ) : false }
        {
          (address?
            <>
              <StyledIconLabel>
                <LocationIcon />
                <BodyText>{address}</BodyText>
              </StyledIconLabel>
            </> : null)
        }
        {
          (contact.ssnSinNumber?
            <>
              <StyledIconLabel>
                <BodyText>SSN/SIN Number:</BodyText>
                <BodyText>{ contact.ssnSinNumber}</BodyText>
              </StyledIconLabel>
            </>  : null)
        }

        {
          (contact.driverLicenseNo?
            <>
              <StyledIconLabel>
                <BodyText>Driver License Number:</BodyText>
                <BodyText>{ contact.driverLicenseNo}</BodyText>
              </StyledIconLabel>
            </>  : null)
        }

        {
          (contact.driverLicenseState?
            <>
              <StyledIconLabel>
                <BodyText>Driver License State:</BodyText>
                <BodyText>{ contact.driverLicenseState}</BodyText>
              </StyledIconLabel>
            </>  : null)
        }

        {
          (contact.dob?
            <>
              <StyledIconLabel>
                <BodyText>Date of Birth:</BodyText>
                <BodyText>{ formatDateToLongDateString(contact.dob)}</BodyText>
              </StyledIconLabel>
            </>  : null)
        }
      </StyledContactInfo>
    </Card>
  )
};
