import React, {useState} from 'react';

import { useMutation } from '@apollo/react-hooks';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { get, getOr } from 'lodash/fp';
import { InitialAvatar } from '@/components/InitialAvatar';

import { Flex } from '@/components/designSystem/Layout';
import { Button } from '@/components/designSystem/buttons';
import { H3, H4 } from '@/components/designSystem/Typography';
import { formatDateToTimeAgo } from '@/utils/date';
import { PDFDocumentModal } from '@/components/PDFDocumentCard/PDFDocumentModal';

import { HiOutlineChatBubbleBottomCenterText, HiOutlineInbox, HiOutlineUserCircle } from "react-icons/hi2";
import { RiPushpinLine, RiUnpinLine } from "react-icons/ri";

import {
  StyledActivityContainer,
  StyledActivityLine,
  StyledIconWrapper,
  StyledIconContainer,
  StyledMessageWrapper,
  StyledEndWrapper,
  StyledTimeWrapper,
  StyledBox,
  StyledAgreementBox,
  StyledActivityList,
  Grid,
  StyledDivider,
  StyledPinButton
} from './styled';

interface IActivity {
  id: string,
  type: string;
  actor: any,
  data: any,
  insertedAt: string
}

interface IProps {
  pinnedActivities: any[],
  activities: any[],
  refetch: () => void
}

interface IActivityItemProps {
  item: IActivity,
  index: number,
  children: React.ReactNode,
  pinned?: boolean,
}

const DiffField = (({diff} : any) => (
  <div>
    {diff.field && <div style={{fontWeight: 700}}>{diff.field}:</div>}
    {diff.removed && <div style={{textDecoration: "line-through", color: "rgb(156 163 175)"}}>{diff.removed}</div>}
    <div>{diff.added}</div>
  </div>

));

const formatStatus = (status: string) => {
  return status
    .replace(/_/g, ' ') 
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

import gql from 'graphql-tag';

export const PIN_ACTIVITY = gql`
  mutation PinActivity($activityId: ID!) {
    pinActivity(activityId: $activityId) {
      id
    }
  }
`;

export const UNPIN_ACTIVITY = gql`
  mutation PinActivity($activityId: ID!) {
    unpinActivity(activityId: $activityId) {
      id
    }
  }
`;

const ActivityItem : React.FC<IActivityItemProps> = ({children, item, index, pinned}) => {

  const ActivityApplicationStarted = ({_item}: any) =>  <></>

  const ActivityAgreementSigned = ({item}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const file = get('data.file', item);

    return(
      <StyledAgreementBox>
        <H3 bold>Agreement signed</H3>
        {file && (
        <>
          <Button onClick={() => setIsOpen(true)} secondary>Preview</Button>
          <PDFDocumentModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            file={{...file, name: file.original_filename, signedUrl: file.signed_url}}
            title={file.original_filename}
          />
        </>
        )}
      </StyledAgreementBox>
    )
  } 

  const ActivityApplicationUpdated = ({item}: any) => {
    const diff = item.data.diff;
    const step = getOr(false, "data.step", item);
    return (
      <StyledBox>
        {!!step && <strong>{step}</strong>}
        {diff.eq && diff.eq.map((item : any) => <DiffField diff={item} />)}
        {diff.del && diff.del.map((item : any) => (
          <div>
            <div style={{fontWeight: 700, color: "rgb(156 163 175)", textDecoration: "line-through"}}>{item.field}</div>
            <div style={{color: "rgb(156 163 175)", textDecoration: "line-through"}}>{item.value}</div>
          </div>
        ))}
        {diff.ins && diff.ins.map((item : any) => (
          <div>
            <div style={{fontWeight: 700}}>{item.field}</div>
            <div>{item.value}</div>
          </div>
        ))}
        <DiffField diff={diff} />
      </StyledBox>
    )
  }

  const ActivityApplicationStatusUpdated = ({item}: any) => {
    const data = item.data;
    const status = getOr('default', 'status', data);
    const map : any = {
      'approved': (
        <StyledBox style={{width: "25%"}}>
          <table style={{width: "100%"}}>
            <tbody>
              <tr>
                <td style={{fontWeight: "bold"}}>Status:</td>
                <td>Approved</td>
              </tr>
              {data.credit_limit && (
                <tr>
                  <td style={{fontWeight: "bold"}}>Credit limit:</td>
                  <td>{data.credit_limit}</td>
                </tr>
              )}
              {data.credit_terms && (
                <tr>
                  <td style={{fontWeight: "bold"}}>Credit term:</td>
                  <td>{data.credit_terms}</td>
                </tr>
              )}
            </tbody>
          </table>
          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      ),
      'default': (
        <StyledBox>
          <span><strong>Status:</strong> {formatStatus(status)}</span>

          {data.comment && <div style={{paddingTop: "1rem"}}>{data.comment}</div>}
        </StyledBox>
      )
    }
    return getOr(map['default'], status, map);
  }

  const ProviderAddInternalComment = ({item}: any) => {
    return (
      <StyledBox>
        {item.data.comment}
      </StyledBox>
    )
  }

  const ActivityEmail = ({item}: any) => {
    return (
      <StyledBox>
        Mailer sent to {item.data.to}
      </StyledBox>
    )
  }

  const ActivityCreateInternalReview = ({item}: any) => {
    const activities = getOr([], 'item.data.custom_field_answers', item);
    const analysis = get('item.data.analysis', item);
    return (
      <StyledBox style={{width: "100%"}}>
        <H3>Information</H3>
        <Flex direction="column" gap="1rem" margin="1rem 0">
        <Grid>
          {activities.map((activity: any) => (
            <Flex direction="column" gap="0.125rem">
              <div style={{border: "1px solid #dadada", padding: "0.5rem"}}><strong>{activity.label}</strong></div>
              <div style={{border: "1px solid #dadada", padding: "0.5rem"}}>{activity.answer}</div>
            </Flex>
          ))}
        </Grid>
        {analysis &&
          <div>
            <H3>Analysis</H3>
            <article dangerouslySetInnerHTML={{__html: analysis}} />
          </div>
        }
        </Flex>
      </StyledBox>
    )
  }

  // Note: Icons are taken from https://heroicons.com/
  const mappings: {[index: string]: any} = {
    application_started: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>started an application</b></span>,
      component: <ActivityApplicationStarted item={item} />
    },
    agreement_signed: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>signed and submitted an application</b></span>,
      component: <ActivityAgreementSigned item={item} />
    },
    application_updated: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>submitted updated information</b></span>,
      component: <ActivityApplicationUpdated item={item} />
    },
    application_status_updated: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>updated application status</b></span>,
      component: <ActivityApplicationStatusUpdated item={item} />
    },
    provider_add_internal_comment: {
      avatar: <HiOutlineChatBubbleBottomCenterText />,
      message: <span>{item.actor.user_name} from {item.actor.company_name}</span>,
      component: <ProviderAddInternalComment item={item} />
    },
    email: {
      avatar: <HiOutlineInbox />,
      message: <span>{item.actor.user_name} sent a {item.data.action_identfier} email to {item.data.to}</span>,
      component: <></>
    },
    provider_assignment: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>assigned</b> the application to <InitialAvatar style={{ marginRight: '0.25rem' }} name={item.data.assigned_to} />{item.data.assigned_to}</span>,
      component: <></>
      // (
      //   <StyledBox>
      //     {item.data.assignment_note}
      //   </StyledBox>
      // )
    },
    create_internal_review: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>created</b> an internal review</span>,
      component: <ActivityCreateInternalReview item={{item}} />
    },
    manual_customer_imported: {
      avatar: <InitialAvatar name={item.actor.user_name} />,
      message: <span>{item.actor.user_name} from {item.actor.company_name} <b>imported</b> this customer as manual customer</span>,
      component: <></>
    },
  }

  const defaultAvatar = <HiOutlineUserCircle />;

  return (
    <Flex direction='column'>
      {!pinned &&
        <StyledActivityLine />
      }
      <Flex direction='row' align='flex-start' position='relative'>
        <StyledIconWrapper>
          <StyledIconContainer>
            {getOr(defaultAvatar, `${item.type}.avatar`, mappings)}
          </StyledIconContainer>
        </StyledIconWrapper>
        <StyledMessageWrapper>
          <Flex direction='row' justify='space-between' align='center'>
            <H4>{get(`${item.type}.message`, mappings)}</H4>
            <StyledEndWrapper>
              <StyledTimeWrapper>
                {formatDateToTimeAgo(item.insertedAt)}
              </StyledTimeWrapper>
              {children}
            </StyledEndWrapper>
          </Flex>
          { get(`${item.type}.component`, mappings)}
        </StyledMessageWrapper>
      </Flex>
    </Flex>
  )
}

export const Activity = ({ pinnedActivities, activities, refetch }: IProps) => {
  const [pinActivity, { loading: pinLoading }] = useMutation(PIN_ACTIVITY);
  const [unpinActivity, { loading: unpinLoading }] = useMutation(UNPIN_ACTIVITY);
  
  const handlePin = async (activityId: string) => {
    const variables = {activityId};
    pinActivity({variables})
      .then(() => {
        showToast({
          title: 'Pinning Activity',
          description: 'Activity pinned to top successfully',
          type: toast.TYPE.SUCCESS,
        });
        refetch()
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something is worng, please try again later',
          type: toast.TYPE.ERROR,
        });
      });
  }

  const handleUnpin = async (activityId: string) => {
    const variables = {activityId};
    unpinActivity({variables})
      .then(() => {
        showToast({
          title: 'Pinning Activity',
          description: 'Activity unpinned from top successfully',
          type: toast.TYPE.SUCCESS,
        });
        refetch()
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something is worng, please try again later',
          type: toast.TYPE.ERROR,
        });
      });
  }

  return (
    <div>
      {pinnedActivities.length !== 0 &&
        <>
          <Flex gap="0.5rem" align="center" style={{padding: '0rem 1.5rem'}}>
            <RiPushpinLine />
            <H4 bold>Pinned Activities</H4>
          </Flex>
          {/* <StyledDivider /> */}
          <StyledActivityList>
            {pinnedActivities.map((activityItem : any, activityItemIdx : any) => (
              <li key={activityItemIdx}>
                <StyledActivityContainer>
                  <ActivityItem item={activityItem} index={activityItemIdx} pinned={true}>
                    <StyledPinButton
                      title='Remove this from top'
                      disabled={unpinLoading}
                      onClick={() => handleUnpin(activityItem.id)}
                    >
                      <RiUnpinLine />
                    </StyledPinButton>
                  </ActivityItem>
                </StyledActivityContainer>
              </li>
            ))}
          </StyledActivityList>
          <StyledDivider style={{ margin: '1rem 0rem'}} />
        </>
      }

      <StyledActivityList>
        {activities.map((activityItem : any, activityItemIdx : any) => (
          <li key={activityItemIdx}>
            <StyledActivityContainer>
              <ActivityItem item={activityItem} index={activityItemIdx} pinned={false}>
                <StyledPinButton
                  title='Put this on top'
                  disabled={pinLoading}
                  onClick={() => handlePin(activityItem.id)}
                >
                  <RiPushpinLine />
                </StyledPinButton>
              </ActivityItem>
            </StyledActivityContainer>
          </li>
        ))}
      </StyledActivityList>
    </div>
  )};
