import { useMemo } from 'react';

import { getOr } from 'lodash/fp';

import { ICustomer } from '@/types/customer';
import { IPendingCustomerInvite } from '@/types/invite';

import { CUSTOM_FIELD_TYPES, VR_STATUS } from '@/constants';
import { IGetDashboardOverviewData } from '@/types/querries';
import { ICustomFieldAnswer } from '@/types/customField';

export const useCustomersData = (data?: IGetDashboardOverviewData) => {
  const customers: ICustomer[] = getOr([], 'listCustomers', data);
  const customerInvites: IPendingCustomerInvite[] = getOr([], 'pendingCustomerInvites', data);
  const generateCustomersData = () => [
    ...customers.map((customer) => {

      const additionalAnswers = customer.customFieldAnswers
        .filter((customFieldAnswer) => customFieldAnswer.customField.type === CUSTOM_FIELD_TYPES.ADDITIONAL_QUESTION);

      return {
        id: customer.id,
        company: customer.seekerCompany.name,
        status: customer.latestApplication?.providerDisplayStatus,
        creditLimit: {
          amount: customer.latestApplication?.creditLimit,
          currency: customer.latestApplication?.creditLimitCurrency
        },
        creditTerms: customer.latestApplication?.creditTerms,
        assignedTo: customer.latestApplication?.assignedTo?.name,
        lastUpdatedBy: customer.latestApplication?.lastUpdatedBy.name,
        lastUpdatedOn: customer.latestApplication?.updatedAt,
        dateApproved: customer.latestApplication?.dateApproved,
        ...additionalAnswers.reduce<Partial<ICustomFieldAnswer>>((acc, additionalAnswer) => ({ ...acc, [additionalAnswer.customField.id]: additionalAnswer.text}), {})
      }}),
    ...customerInvites.map((customerInvite) => ({
      id: `referral/${customerInvite.referralCode}`,
      company: customerInvite.toCompanyName,
      status: customerInvite.sent ? 'Sent' : 'Created',
      lastUpdatedBy: customerInvite.from.name,
      vendorReference: VR_STATUS.FAILURE,
      bankReference: VR_STATUS.PENDING,
    }))
  ];

  const customersData = useMemo(() => generateCustomersData(), [generateCustomersData]);

  return customersData;
};
