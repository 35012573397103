import React, {useState } from 'react';

import { Formik, Form } from 'formik';

import { get } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';
import { formatDateToTimeAgo } from '@/utils/date';

import { IVendor } from '@/types/vendor';
import { IVRCustomField } from '@/types/vrCustomField';
import { ICustomer } from '@/types/customer';

import { ToggleBlock } from '@/components/ToggleBlock';
import { BodyText, StyledExternalLink } from '@/components/designSystem/Typography';

import { StyledToggleReferenceHeader, StyledToggleReferenceContentHeaderContainer } from '../../../styled';
import { useReferenceStatusInfo } from './useReferenceStatusInfo';
import { useVRContact } from './useVRContact';
import { useFields } from './useFields';
import { StyledAddVendorWrapper, StyledEditButton, StyledIconLabel } from './styled';
import { ReferenceUpdateView } from './ReferenceUpdateView';
import { ReferenceInfoView } from './ReferenceInfoView';
import { AddVendor } from '../AddVendor';

import { ReactComponent as EmailIcon } from '@/assets/email_contact.svg';
import { ReactComponent as PhoneIcon } from '@/assets/phone_contact.svg';
import { WARNINGS } from '@/constants';
import { WarningPopupIcon } from '@/components/WarningPopupIcon';

export interface IShownVendorCustomFields {
  address: boolean,
  website: boolean,
  accountNumber: boolean,
  currentBalance: boolean,
}

interface IProps {
  customerData?: ICustomer,
  reference: IVendor,
  vrCustomFields: IVRCustomField[],
  isApplicationInProgress: boolean,
  refetch: () => void,
  canSeeEditControl: boolean,
}

const VR_CUSTOM_FIELDS = {
  ADDRESS: 'address',
  WEBSITE: 'websiteAddress',
  ACCOUNT_NUMBER: 'accountNumber',
  CURRENT_BALANCE: 'currentBalance',
}

const OPT = true;
const AT = '12 may'

export const Reference = ({ customerData, reference, refetch, vrCustomFields, isApplicationInProgress, canSeeEditControl }: IProps) => {
  const [isEditActive, setEditActive] = useState(false);
  const [submitFrom, setSubmitFrom] = useState<string | null>(null);
  const [isEditVendorsActive, setEditVendorsActive] = useState(false);

  const title = get('sourceCompany.name', reference) || get('sourceUser.company', reference);
  const optOut = get('sourceCompany.optOut', reference);
  const optOutAt = get('sourceCompany.optOutAt', reference);

  const shownVendorCustomFields = {
    address: vrCustomFields.find((field) => field.name === VR_CUSTOM_FIELDS.ADDRESS)?.enabled ?? false,
    website: vrCustomFields.find((field) => field.name === VR_CUSTOM_FIELDS.WEBSITE)?.enabled ?? false,
    accountNumber: vrCustomFields.find((field) => field.name === VR_CUSTOM_FIELDS.ACCOUNT_NUMBER)?.enabled ?? false,
    currentBalance: vrCustomFields.find((field) => field.name === VR_CUSTOM_FIELDS.CURRENT_BALANCE)?.enabled ?? false,
  }

  const { fields, handleSubmit } = useFields({
    reference,
    submitFrom,
    setSubmitFrom,
    refetch,
    setEditActive
  });

  const referenceStatusInfo = useReferenceStatusInfo(reference, refetch, isApplicationInProgress, canSeeEditControl);
  const {
    vrContactName,
    vrContactPosition,
    vrContactEmail,
    vrContactPhoneNumber,
    vrPending,
    showNonBusinessEmailDomainWarning,
  } = useVRContact(reference);

  const Header = (
    <StyledToggleReferenceHeader>
      <BodyText bold>{title}{(optOut && optOutAt) ? ` (has opted out at ${formatDateToTimeAgo(optOutAt)})` : false}</BodyText>
      {referenceStatusInfo}
    </StyledToggleReferenceHeader>
  )

  const renderContentHeader = () => {
    if (vrPending) {
      return (
        <>
          {isEditVendorsActive ? (
            <StyledAddVendorWrapper>
              <AddVendor setAddVendorsActive={setEditVendorsActive} reference={reference} refetch={refetch} customerData={customerData}/>
            </StyledAddVendorWrapper>
          ) : (
            <StyledToggleReferenceContentHeaderContainer>
              <StyledIconLabel>
                {vrContactName}{vrContactPosition || ''}
              </StyledIconLabel>
              <StyledIconLabel>
                <EmailIcon />
                <StyledExternalLink href={`mailto:${vrContactEmail}`}>{vrContactEmail}</StyledExternalLink>
                {(showNonBusinessEmailDomainWarning) ? (
                  <WarningPopupIcon style={{ marginLeft: '0.25rem'}} description={WARNINGS.DOMAIN} />
                ) : ''}
              </StyledIconLabel>
              {(vrContactPhoneNumber) ? (
                <StyledIconLabel>
                  <PhoneIcon />
                  <BodyText>{vrContactPhoneNumber}</BodyText>
                </StyledIconLabel>
              ) : false }
              <StyledEditButton onClick={()=> setEditVendorsActive(true)} buttonText='Edit Contact' />
            </StyledToggleReferenceContentHeaderContainer>
          )
          }
        </>
      )
    }
    return (
      <StyledToggleReferenceContentHeaderContainer>
        <StyledIconLabel>
          {vrContactName}{vrContactPosition || ''}
        </StyledIconLabel>
        <StyledIconLabel>
          <EmailIcon />
          <StyledExternalLink href={`mailto:${vrContactEmail}`}>{vrContactEmail}</StyledExternalLink>
          {(showNonBusinessEmailDomainWarning) ? (
            <WarningPopupIcon style={{ marginLeft: '0.25rem'}} description={WARNINGS.DOMAIN} />
          ) : ''}
        </StyledIconLabel>
        {(vrContactPhoneNumber) ? (
          <StyledIconLabel>
            <PhoneIcon />
            <BodyText>{vrContactPhoneNumber}</BodyText>
          </StyledIconLabel>
        ) : false }
      </StyledToggleReferenceContentHeaderContainer>
    )
  }

  return (
    <ToggleBlock
      header={Header}
    >
      {renderContentHeader()}
      <Formik onSubmit={(values) => handleSubmitWrapper(values, handleSubmit)} initialValues={fields} enableReinitialize>
        <Form>
          {isEditActive ? (
            <ReferenceUpdateView
              reference={reference}
              refetch={refetch}
              setSubmitFrom={setSubmitFrom}
              shownVendorCustomFields={shownVendorCustomFields}
              isEditActive={isEditActive}
              setEditActive={setEditActive}
            />
          ) : (
            <ReferenceInfoView
              customerData={customerData}
              reference={reference}
              refetch={refetch}
              fields={fields}
              canSeeEditControl={canSeeEditControl}
              shownVendorCustomFields={shownVendorCustomFields}
              isEditActive={isEditActive}
              setEditActive={setEditActive}
            />
          )}
        </Form>
      </Formik>
    </ToggleBlock>
  )}
