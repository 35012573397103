import React, {useEffect} from 'react';
import Select from 'react-select';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_TAG_BY_ID, GET_TAGS_BY_PROVIDER_ID, GET_TAGS_BY_APPLICATION_ID } from '@/graphql/queries/getTag';
import { CREATE_APPLICATION_TAG } from '@/graphql/mutations/createTag';
import { DELETE_APPLICATION_TAG } from '@/graphql/mutations/DeleteTag';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { LoadingSpinner } from '@/components/LoadingSpinner';

import {
  StyledMultiSelectOption,
  StyledMultiValueContainer,
  StyledMultiValueLabel,
  StyledRemoveIcon
} from './styled';

interface TagProps {
  id: string;
  tagName: string;
  tagBackColor: string;
  tagFrontColor: string;
}

interface ApplicationTagProps {
  id: string;
  tagId: string;
  applicationId: string;
}

interface IProps {
  currentCompanyId: string;
  applicationId: string;
}

const CustomOption = (props: any) => {
  return (
    <StyledMultiSelectOption
      {...props.innerProps}
      backgroundColor={props.data.tagBackColor}
      textColor={props.data.tagFrontColor}
    >
      {props.data.tagName}
    </StyledMultiSelectOption>
  );
};

const CustomMultiValueContainer = (props: any) => {
  const { data: tags, loading } = useQuery(GET_TAG_BY_ID, {
    variables: { tagId: props.data.tagId },
  });
  
  const [deleteApplicationTag] = useMutation(DELETE_APPLICATION_TAG);

  const tag: TagProps = tags?.getTagById;
  
  const handleRemove = async () => {
    try {
      await deleteApplicationTag({
        variables: {
          tagId: props.data.tagId,
          applicationId: props.data.applicationId,
        },
      });

      props.refetchAppTags();

      showToast({
        title: 'Tag removed',
        description: `${tag?.tagName} has been removed.`,
        type: toast.TYPE.SUCCESS,
      });

    } catch (error) {
      showToast({
        title: 'Error',
        description: 'Failed to remove the tag.',
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <StyledMultiValueContainer
      {...props.innerProps}
      backgroundColor={tag?.tagBackColor}
      textColor={tag?.tagFrontColor}
    >
      <StyledMultiValueLabel
        backgroundColor={tag?.tagBackColor}
        textColor={tag?.tagFrontColor}
      >
        {loading ? <LoadingSpinner size={10} /> : <>{tag?.tagName}</>}
      </StyledMultiValueLabel>
      
      <StyledRemoveIcon
        backgroundColor={tag?.tagBackColor}
        textColor={tag?.tagFrontColor}
        onClick={handleRemove}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="14" width="14">
          <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
        </svg>
      </StyledRemoveIcon>
    </StyledMultiValueContainer>
  );
};

export const TagForm = ({ currentCompanyId, applicationId }: IProps) => {
  const { data: compnay_tags } = useQuery(GET_TAGS_BY_PROVIDER_ID, { 
    variables: { providerCompanyId: currentCompanyId } 
  });

  const defaultTags: TagProps[] = compnay_tags?.getTagsByProviderId || [];

  const { data: application_tags, refetch: refetchAppTags } = useQuery(GET_TAGS_BY_APPLICATION_ID, { 
    variables: { applicationId: applicationId } 
  });

  const applicationTags: ApplicationTagProps[] = application_tags?.getTagsByApplicationId || [];

  const [updateApplicationTag] = useMutation(CREATE_APPLICATION_TAG);

  const handleChange = (selectedTags: TagProps[]) => {
    const newlyAddedTags = selectedTags.filter(
      (tag) => !applicationTags.some((existingTag) => existingTag.id === tag.id)
    );
    
    newlyAddedTags.forEach((tag: TagProps) => {
      updateApplicationTag({
        variables: {
          tagId: tag.id,
          applicationId: applicationId,
        },
      })
        .then(() => {
          showToast({
            title: 'Tag added',
            description: `Tag ${tag.tagName} added to application`,
            type: toast.TYPE.SUCCESS,
          });
          refetchAppTags()
        })
        .catch(() => {
          showToast({
            title: 'Error',
            description: 'Failed to add tag.',
            type: toast.TYPE.ERROR,
          });
        });
    });
  };

  useEffect(() => {
    if (refetchAppTags) {
      refetchAppTags();
    }
  }, [refetchAppTags, applicationId]);

  return (
    <Select
      styles={{
        control: (baseStyles: any) => ({
          ...baseStyles,
          minWidth: '12rem',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '0.5rem',
          "&:hover": {
            backgroundColor: "#f3f4f6",
          },
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        menu: (provided: any) => ({ ...provided, width: '13rem' }),
        multiValue: (base: any, state: any) => ({
          ...base,
          backgroundColor: state.data.tagBackColor,
          color: state.data.tagFrontColor,
        })
      }}
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
      options={defaultTags.filter(
        (tag) => !applicationTags.some((selectedTag) => selectedTag.tagId === tag.id)
      )}
      getOptionLabel={(option: any) => option.tagName}
      getOptionValue={(option: any) => option.id}
      components={{
        Option: CustomOption,
        MultiValueContainer: (props: any) => (
        <CustomMultiValueContainer 
          {...props} 
          refetchAppTags={refetchAppTags}
        />
      ) }}
      placeholder={`No Tags`}
      value={applicationTags}
      onChange={handleChange}
    />
  )
}