import React, { useContext, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_TAG } from '@/graphql/mutations/createTag';
import { DELETE_TAG } from '@/graphql/mutations/DeleteTag';
import { GET_TAGS_BY_PROVIDER_ID } from '@/graphql/queries/getTag';
import { GET_PARENT_COMPANY } from '@/graphql/queries/getParentCompany';

import { Formik, FormikHelpers } from 'formik';
import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { get } from 'lodash/fp';
import { roleFallback } from '@/utils/roleFallback';

import { IUserCompanyRole } from '@/types/companyUser';

import { CurrentUserContext } from '@/providers/CurrentUser';
import { CurrentCompanyContext } from '@/providers/CurrentCompany';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { BorderedContainer } from '@/containers/BorderedContainer';

import { TextField } from '@/components/designSystem/Form/TextField';
import { ColorPickerField } from '@/components/designSystem/Form/ColorPicker/ColorPickerField';
import { H3, H4, BodyText } from '@/components/designSystem/Typography';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Button } from '@/components/designSystem/buttons';
import { Flex } from '@/components/designSystem/Layout';
import Tiptap from '@/components/designSystem/Form/Editor/Tiptap';

import { StyledTagForm, Container, Column, ButtonContainer, StyledTag, TagContainer } from './styled';
import { HiMiniXMark } from "react-icons/hi2";

interface TagValues {
  tagName: string;
  tagBackColor: string;
  tagFrontColor: string;
}

const tagsValidation = Yup.object().shape({
  tagName: Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED),
  tagBackColor: Yup.string(),
  tagFrontColor: Yup.string(),
});

export const TagSettingsForm = () => {
  const [companyId] = useLocalStorage('company-id');

  const { data: parentCompany } = useQuery(GET_PARENT_COMPANY);

  const currentUserData = useContext(CurrentUserContext);
  const currentCompanyData = useContext(CurrentCompanyContext);

  const currentCompanyLoading = get('loading', currentCompanyData);

  const parentCompanyId = get('parentCompany.id', parentCompany);
  const currentCompanyId = get('currentCompany.id', currentUserData) || companyId;
  const userCompanyRoles = get('currentUser.userCompanyRole', currentUserData)
  // eslint-disable-next-line max-len
  const currentUserCompanyRole = roleFallback(
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === currentCompanyId),
    userCompanyRoles.filter((ucr: IUserCompanyRole) => ucr.company.id === parentCompanyId)
  );
  const changeTemplate = get('role.permissions.changeTemplate', currentUserCompanyRole[0]) || false;

  if (currentCompanyLoading) {
    return <LoadingSpinner />;
  }

  const initialTagValue: TagValues = {
    tagName: '',
    tagBackColor: '#ff0000',
    tagFrontColor: '#ffffff'
  }

  const { data: compnay_tags, refetch: refetchTags } = useQuery(GET_TAGS_BY_PROVIDER_ID, { 
    variables: { providerCompanyId: currentCompanyId } 
  });

  const [updateCompanytags] = useMutation(CREATE_TAG);
  const [deleteCompanyTags] = useMutation(DELETE_TAG);

  const saved_tags: TagValues[] = compnay_tags?.getTagsByProviderId || [];

  const SaveTag = (values: TagValues, { resetForm }: FormikHelpers<TagValues>) => {
    const variables = {
      tag_input: {
        tag_name: values.tagName,
        tag_front_color: values.tagFrontColor,
        tag_back_color: values.tagBackColor,
        company_id: currentCompanyId
      }
    };

    return updateCompanytags({ variables })
      .then(() => {
        showToast({
          title: 'New Tag added',
          description: 'New Tag added.',
          type: toast.TYPE.SUCCESS,
        });
        refetchTags();
        resetForm();
      });
  };
  
  const removeTag = (tag: any) => {
    deleteCompanyTags({ variables: { tagId: tag.id } })
      .then(() => {
        showToast({
          title: 'Tag deleted',
          description: `${tag.tagName} has been removed.`,
          type: toast.TYPE.SUCCESS,
        });
        refetchTags();
      })
      .catch((error) => {
        showToast({
          title: 'Error',
          description: 'Failed to delete tag.',
          type: toast.TYPE.ERROR,
        });
        console.error(error);
      });
  };

  return (
    <Formik
      initialValues={initialTagValue}
      validationSchema={tagsValidation}
      onSubmit={SaveTag}
    >
      {changeTemplate &&
        <>
          <BorderedContainer disableDivider>
            <H3 bold>Tag Settings</H3>
            
            <Container style={{ flexDirection: 'column'}}>
              <H4 bold>Current Tags</H4>
              <TagContainer>
                {saved_tags.length > 0 ? (
                  saved_tags.map((tag, tagIdx) => (
                    <StyledTag
                      key={tagIdx}
                      backgroundColor={tag.tagBackColor}
                      textColor={tag.tagFrontColor}
                    >
                      <span>{tag.tagName}</span>
                      <button onClick={() => removeTag(tag)}>
                        <HiMiniXMark />
                      </button>
                    </StyledTag>
                  ))
                ) : (
                  <BodyText>No tags added</BodyText>
                )}
              </TagContainer>
            </Container>

            <StyledTagForm style={{ gap: '1rem' }}>
              <TextField type='text' required name='tagName' label='Tag' placeholder='Name' />              
              <ColorPickerField required name='tagBackColor' label='Background Color' />
              <ColorPickerField required name='tagFrontColor' label='Text Color' />
              <ButtonContainer style={{ marginTop: '2.25rem' }}>
                <Button primary type='submit'>Add Tag</Button>
              </ButtonContainer>
            </StyledTagForm>
          </BorderedContainer>
        </>
      }
    </Formik>
  )
}