import React from 'react';

import { useField, ErrorMessage } from 'formik';
import { getCurrencySymbol } from '@/utils/money';

import { FieldLabel } from './FieldLabel';
import { StyledDescriptionText, StyledErrorText, StyledFieldContainer, StyledNumberFormatInput } from './styled';
import { SelectField } from './SelectField';
import { Flex } from '@/components/designSystem/Layout';
import { CURRENCY_OPTIONS } from '@/utils/money';

interface INumberFormatInputValue {
  formattedValue: string,
  value: string,
  floatValue: number,
}

interface IProps {
  name?: string,
  label?: string,
  currency: string,
  required?: boolean,
  description?: string,
  disabled?: boolean,
  editableCurrency?: boolean
}

export const CurrencyField = ({
  required,
  name = '',
  label,
  currency,
  description,
  disabled,
  editableCurrency = false
}: IProps) => {
  const [field, meta, helpers] = useField(name);
  const [currencyField] = useField(`${name}Currency`);
  const showError = !!(meta.touched && meta.error);
  const { setValue, setTouched } = helpers;
  const cCurrency = editableCurrency ? getCurrencySymbol(currencyField.value) : getCurrencySymbol(currency);

  return (
    <StyledFieldContainer>
      {label ? <FieldLabel name={name} required={required}>{label}</FieldLabel> : false}
      <Flex gap='1rem'>
        {editableCurrency &&
          <SelectField name={`${name}Currency`} defaultValue={currency} options={CURRENCY_OPTIONS} />
        }
        <StyledNumberFormatInput
          value={field.value}
          onValueChange={(value: INumberFormatInputValue) => setValue(value.floatValue ?? null)}
          onBlur={() => setTouched(true)}
          prefix={cCurrency}
          isNumericString
          thousandSeparator
          decimalScale={2}
          placeholder={cCurrency}
          error={showError}
          disabled={disabled}
          style={{width: "100%"}}
        />
      </Flex>
      {description && <StyledDescriptionText>{description}</StyledDescriptionText>}
      {(showError && name) &&
        <StyledErrorText>
          { (showError && name) ? <ErrorMessage name={name} /> : false }
        </StyledErrorText>
      }
    </StyledFieldContainer>
  )
};
