/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { INavOptionsGroup } from '@/types/navOptions';

import { NavigationItem, ChildNavigationItem } from './NavigationItem';
import { LinkItem, ChildLinkItem } from './LinkItem';
import { NavBarIcon } from './NavBarIcon';

import { useApplicationStatus } from '@/providers/ApplicationStatus';

import {
  NavGroupContainer,
  StyledDivider,
  NavItemText,
  StyledTooltip,
  NavCollapseItem,
  NavChildrenWrapper,
  StyledNavCollapseBlock,
  StyledCollapseToggleIcon
} from './styled';

interface IProps {
  options: INavOptionsGroup,
  index: number,
  toggleMenu?: () => void,
}

export const NavigationGroup = ({ options, index, toggleMenu }: IProps) => {
  const { pathname } = useLocation();
  const {appStatus} = useApplicationStatus();
  const [openIndexes, setOpenIndexes] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const newOpenIndexes: Record<number, boolean> = {};
    options.forEach((option, i) => {
      if (option.children) {
        option.children.forEach(child => {
          if (
            (child.path && pathname.startsWith(child.path)) ||
            (appStatus && child.title === appStatus)
          ) {
            newOpenIndexes[i] = true;
          }
        });
      }
    });
    setOpenIndexes(newOpenIndexes);
  }, [pathname, options, appStatus]);

  const toggleOpen = (index: number) => {
    setOpenIndexes(prev => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <NavGroupContainer index={index}>
        {options.map((option, i) => (
          <div key={i}>
            {option.children ? (
              <NavCollapseItem>
                <StyledNavCollapseBlock onClick={() => toggleOpen(i)}>
                  <div>
                    <NavBarIcon title={option.title} />                
                    <NavItemText className='nav-item-text' bold>
                      {option.title}
                    </NavItemText>
                  </div>
                  <StyledCollapseToggleIcon isOpen={!!openIndexes[i]} />
                  <StyledTooltip className='tooltip'>{option.title}</StyledTooltip>
                </StyledNavCollapseBlock>
                <NavChildrenWrapper isOpen={!!openIndexes[i]}>
                  {option.children.map((item, j) => (
                    item.path
                      ? <ChildNavigationItem key={j} {...item} toggleMenu={toggleMenu} />
                      : <ChildLinkItem key={j} {...item} toggleMenu={toggleMenu} />
                  ))}
                </NavChildrenWrapper>
              </NavCollapseItem>
            ) : (
              option.path
                ? <NavigationItem key={i} {...option} toggleMenu={toggleMenu} />
                : <LinkItem key={i} {...option} toggleMenu={toggleMenu} />
            )}
          </div>
        ))}
      </NavGroupContainer>
      {(index === 0) && (
        <StyledDivider />
      )}
    </>
  )
};
