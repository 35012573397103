import React, { useContext } from 'react';

import { Formik } from 'formik';

import { get } from 'lodash/fp';
import { formatDateToDateTimeString } from '@/utils/date';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { ICustomer } from '@/types/customer';

import { CurrentUserContext } from '@/providers/CurrentUser';

import { Button } from '@/components/designSystem/buttons';
import { BodyText, SubHeading } from '@/components/designSystem/Typography';
import { TextAreaField } from '@/components/designSystem/Form/TextAreaField';
import { Flex } from '@/components/designSystem/Layout';

import { SectionContainer } from '../../SectionContainer';
import { StyledGrayBox } from '../../styled';
import { useCommentsForm } from './useCommentsForm';
import {
  StyledCommentInfo,
  StyledCommentDate,
  StyledCommentsForm,
  StyledCommentInput,
  StyledCommentButtonContainer,
} from './styled';

interface IProps {
  customerData: ICustomer,
  refetch: () => void,
  canSeeEditControl: boolean,
}

export const Comments = ({ customerData, refetch, canSeeEditControl }: IProps) => {
  const { latestApplication } = customerData;

  const currentUserData = useContext(CurrentUserContext);

  const currentUserId = get('currentUser.id', currentUserData);
  const internalComments = get('creditProviderInternalComments', latestApplication);

  const internalCommentsSortedByDate = [...internalComments].sort((a, b) => (new Date(a.insertedAt) < new Date(b.insertedAt)) ? 1 : -1);

  const {
    fields,
    validation,
    handleSubmit,
    loading
  } = useCommentsForm(
    latestApplication.id,
    currentUserId,
    refetch,
  );

  return (
    <SectionContainer title='Internal Comments'>
      {canSeeEditControl ? (
        <Formik initialValues={fields} onSubmit={(values, options) => handleSubmitWrapper(values, handleSubmit, options)} validationSchema={validation}>
          <StyledCommentsForm>
            <StyledCommentInput name='comment' placeholder='Add a comment...' />
            <StyledCommentButtonContainer>
              <Button
                type='submit'
                primary
                loading={loading}
              >
                Post
              </Button>
            </StyledCommentButtonContainer>
          </StyledCommentsForm>
        </Formik>) : false}
    </SectionContainer>
  )
};

