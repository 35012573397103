import React, { useState, Dispatch, SetStateAction } from 'react';

import { FetchResult } from '@apollo/react-hooks';

import { CustomerTypes } from './CustomerTypes';
import { CreditCustomer } from './CreditCustomer';
import { ManualCustomer } from './ManualCustomer';
import { ImportCustomer } from './ImportCustomer';
import { StepperModal } from '../StyledModal/StepperModal';

interface IProps {
  currentCompanyName: string
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  onSuccess: (response: FetchResult) => void,
}

// eslint-disable-next-line no-shadow
export enum VIEWS {
  CUSTOMER_TYPES = 'CUSTOMER_TYPES',
  CREDIT_CUSTOMER = 'CREDIT_CUSTOMER',
  MANUAL_CUSTOMER = 'MANUAL_CUSTOMER',
  IMPORT_CUSTOMER = 'IMPORT_CUSTOMER',
}

export const AddCustomerModal = ({ currentCompanyName, isOpen, setIsOpen, onSuccess }: IProps) => {
  const [activeView, setActiveView] = useState<keyof typeof VIEWS>(VIEWS.CUSTOMER_TYPES);

  const views = {
    CUSTOMER_TYPES: <CustomerTypes setActiveView={setActiveView} />,
    CREDIT_CUSTOMER: <CreditCustomer setIsOpen={setIsOpen} onSuccess={onSuccess} />,
    MANUAL_CUSTOMER: <ManualCustomer setIsOpen={setIsOpen} onSuccess={onSuccess} />,
    IMPORT_CUSTOMER: <ImportCustomer setIsOpen={setIsOpen} onSuccess={onSuccess} />,
  }

  const handleAfterClose = () => {
    setActiveView(VIEWS.CUSTOMER_TYPES);
  };

  return (
    <StepperModal
      title={`New Customer for ${currentCompanyName}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width='80vw'
      maxWidth='1200px'
      height='800px'
      blockClose={true}
      afterClose={handleAfterClose}
    >
      {views[activeView]}
    </StepperModal>
  );
};
