import Yup, { DEFAULT_ERROR_MESSAGES } from '@/utils/yup';

import { getValidationForCustomFields } from '@/utils/customFields';

import { ICustomField } from '@/types/customField';
import { ISettings } from '@/types/settings';
import { getOr } from 'lodash/fp';

export const otherInfoStepValidation = (
  companySettings: ISettings,
  customFields: ICustomField[],
  requireEinFederalTaxNumber: boolean,
) => {
  const requireCreditLimit = getOr(true, 'requireCreditLimit', companySettings);
  const requireCreditTerms = getOr(true, 'requireCreditTerms', companySettings);

  return Yup.object().shape({
    creditLimit: requireCreditLimit ? Yup.number().nullable().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.number().nullable(true),
    creditTerms: requireCreditTerms ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string().nullable(true),
    einFederalTaxNumber: requireEinFederalTaxNumber ? Yup.string().required(DEFAULT_ERROR_MESSAGES.REQUIRED) : Yup.string(),
    customFields: Yup.object().shape(getValidationForCustomFields(customFields)),
  })
}
