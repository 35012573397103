import styled from 'styled-components';

import { Flex, FlexRow } from '@/components/designSystem/Layout';
import { Caption, BodyText } from '@/components/designSystem/Typography';
import { StyledTextArea } from '@/components/designSystem/Form/styled';
import { Form } from 'formik';

// COMMENTS

export const StyledCommentsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 2rem;
  position: relative;
`

export const StyledCommentInput = styled(StyledTextArea)`
  min-height: 100px;
  padding-bottom: 2.75rem;
`

export const StyledCommentButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
  width: 100%;
`

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0.125rem;
  padding: 0.125rem;
`;

export const StyledCommentInfo = styled(FlexRow)`
  gap: 1rem;
`

export const StyledCommentDate = styled(Caption)`
  color: ${({ theme }) => theme.color.greyMed};
`;

// deprecated
export const StyledActivityContainer = styled.div`
  position: relative;
  padding-bottom: 1.5rem;
`;

export const StyledActivityLine = styled.span`
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
  height: 100%;
  width: 0.125rem;
  background-color: #e5e7eb;
  margin-left: -1px;
`;

export const StyledActivityList = styled.ul`
  padding: 1rem;
  list-style: none;
  margin: 0;
  li:last-child ${StyledActivityLine} {
    display: none;
  }
`

export const StyledTypeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`;

export const StyledIconWrapper = styled.div`
  position: relative;
  padding: 0rem 0.25rem;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: #f3f4f6;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const StyledMessageWrapper = styled.div`
  min-width: 0px;
  flex: 1 1 0%;
  margin-left: 1rem;
`;

export const StyledEndWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const StyledTimeWrapper = styled.span`
  font-size: 0.875rem;
  color: #6b7280;
  margin-right: 0.25rem;
`;

export const StyledBox = styled.div`
  margin-top: 0.5rem;
  padding: 1rem;
  border: ${({ theme }) => theme.color.border.light};
  border-radius: 6px;
  width: fit-content;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

export const StyledAgreementBox = styled(StyledBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const StyledChangeBox = styled(StyledBox)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-width: fit-content;
`;

export const StyledUpdateGrid = styled.span`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const StyledBodyTextStrikeThrough = styled.span`
  text-decoration-line: line-through;
  color: #9ca3af;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
`

export const StyledDivider = styled.div`
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.blackTrans16};
`;

export const StyledPinButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  color: #6b7280;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;