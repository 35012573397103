import React, { useState } from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { get } from 'lodash/fp';
import { some } from 'lodash';

import { BodyText, H2 } from '@/components/designSystem/Typography';
import { FlexColumn, FlexRowEnd } from '@/components/designSystem/Layout';
import { PhoneNumberField } from '@/components/designSystem/Form/PhoneNumberField';
import { GoogleAddressField } from '@/components/GoogleAddressField';
import { FileUploadField } from '@/components/designSystem/Form/FileUploadField';
import { TextField } from '@/components/designSystem/Form/TextField';
import { Button } from '@/components/designSystem/buttons';
import { StyledFormRow, StyledMultiRow } from '@/components/designSystem/Form/styled';

import { IManualAccountFields } from '@/modals/AddManualInstitutionModal/AccountSection';

import { ReactComponent as CloseIcon } from '@/assets/x_close.svg';
import { ReactComponent as Plus } from '@/assets/plus.svg';

import { setFieldsFromGooglePlace } from './functions';
import { AccountSection } from './AccountSection';
import { IFinancialHealthValues } from '../../../types';
import { initialManualBankAccountFields } from '../../../initialValues';
import {
  StyledCalloutAction,
  StyledCalloutContainer,
  StyledTitleContainer,
  StyledFlexButton,
  StyledUploadBankStatementsContainer,
  StyledCloseIconWrapper,
  StyledManualBankContainer
} from '../../../styled';

interface IProps {
  parentKey: string,
  disabled?: boolean,
  onRemoveClick?: () => void,
  index?: number,
  requestBankStatements?: boolean,
}

export const ManualBankSection = ({
  parentKey,
  disabled = false,
  onRemoveClick,
  index = 0,
  requestBankStatements = false
}: IProps) => {
  const { values, setFieldValue } = useFormikContext<IFinancialHealthValues>();

  const initDisplayUploadBankStatement = some(get(`${parentKey}.bankStatements`, values));
  const [displayUploadBankStatements, setDisplayUploadBankStatements] = useState(initDisplayUploadBankStatement);

  return (
    <StyledManualBankContainer>
      <FlexRowEnd>
        {(onRemoveClick && Number(index) > 0) && (
          <StyledCloseIconWrapper>
            <CloseIcon onClick={onRemoveClick} />
          </StyledCloseIconWrapper>
        )}
      </FlexRowEnd>
      <StyledFormRow>
        <TextField required type='text' disabled={disabled} name={`${parentKey}.bankName`} label='Bank Name' placeholder='Bank of America' />
        <GoogleAddressField
          type='text'
          name={`${parentKey}.bankAddress`}
          label='Bank Address'
          required
          disabled={disabled}
          placeholder='123 example st, New York, NY, USA, 10001'
          setFieldsFromGooglePlace={(place) => setFieldsFromGooglePlace(place, parentKey, setFieldValue)}
        />
      </StyledFormRow>
      <StyledMultiRow>
        <StyledFormRow>
          <TextField
            type='text'
            name={`${parentKey}.contactName`}
            label='Bank Contact Name'
            required
            disabled={disabled}
            placeholder='John Doe'
          />
          <TextField
            type='text'
            name={`${parentKey}.contactEmail`}
            label='Bank Contact Email'
            required
            disabled={disabled}
            placeholder='john@example.org'
          />
        </StyledFormRow>
        <StyledFormRow>
          <PhoneNumberField
            name={`${parentKey}.contactPhone`}
            label='Bank Contact Phone'
            required
            disabled={disabled}
            placeholder='Phone number'
          />
          <TextField
            type='text'
            name={`${parentKey}.contactTitle`}
            label='Bank Contact Title'
            required
            disabled={disabled}
            placeholder='Branch Manager'
          />
        </StyledFormRow>
      </StyledMultiRow>
      <FieldArray
        name={`${parentKey}.accounts`}
        render={(arrayHelpers) => (
          <>
            {get(`${parentKey}.accounts`, values).map((_owner: IManualAccountFields, accountIndex: number) => (
              <AccountSection
                // eslint-disable-next-line react/no-array-index-key
                key={accountIndex}
                index={accountIndex}
                parentKey={`${parentKey}.accounts[${accountIndex}]`}
                handleRemoveClick={() => arrayHelpers.remove(accountIndex)}
                disabled={disabled}
              />
            ))
            }
            { !disabled
              ? (<Button
                secondary
                LeftIcon={Plus}
                onClick={() => arrayHelpers.push(initialManualBankAccountFields)}
              >
                Add Account
              </Button>)
              : false }
            {requestBankStatements ? (
              <StyledCalloutContainer>
                <StyledCalloutAction color='tagYellow'>
                  <FlexColumn>
                    <H2 bold>Want faster approval?</H2>
                    <BodyText>If you upload bank statements approval process will be faster, it is completely optional.</BodyText>
                  </FlexColumn>
                  <StyledFlexButton fullHeight primary bold onClick={() => setDisplayUploadBankStatements(!displayUploadBankStatements)}>Upload Bank Statements (Optional)</StyledFlexButton>
                </StyledCalloutAction>
              </StyledCalloutContainer>
            ) : false}
          </>
        )}
      />
      {(displayUploadBankStatements && requestBankStatements) ? (
        <StyledUploadBankStatementsContainer>
          <StyledTitleContainer>
            <H2 bold>Upload Bank Statements</H2>
            <BodyText>Upload your <strong>3 most recent</strong> bank statements to manually verify your bank account.</BodyText>
          </StyledTitleContainer>
          <FileUploadField
            name={`${parentKey}.bankStatements`}
            label='Bank Statements'
            acceptFiles='application/pdf, image/jpeg, image/png'
            description='Only pdf, jpeg/jpg and png files are accepted'
          />
        </StyledUploadBankStatementsContainer>
      ) : false}
    </StyledManualBankContainer>
  );
};
