import React from 'react';

import { useFormikContext } from 'formik';

import { ICustomField } from '@/types/customField';

import { H2 } from '@/components/designSystem/Typography';
import { FlexRowEnd } from '@/components/designSystem/Layout';
import { TextField } from '@/components/designSystem/Form/TextField';
import { SelectField } from '@/components/designSystem/Form/SelectField';
import { CurrencyField } from '@/components/designSystem/Form/CurrencyField';
import { CustomField } from '@/components/CustomField/CustomField';
import { StyledFormRow } from '@/components/designSystem/Form/styled';
import { getOr } from 'lodash/fp';

import {
  StyledTitleContainer,
  StyledNextButton,
  StyledForm,
} from '../../styled';
import { IOtherInfoValues } from './types';
import { useAutoscrollToError } from '../../../useAutoscrollToError';
import { ISettings } from '@/types/settings';

interface IProps {
  companySettings: ISettings,
  customFields: ICustomField[],
  creditTermsOptions: string[],
  requireEinFederalTaxNumber: boolean,
}

export const OtherInfoStepForm = ({ companySettings, customFields, creditTermsOptions, requireEinFederalTaxNumber }: IProps) => {
  const { errors, isSubmitting } = useFormikContext<IOtherInfoValues>();

  useAutoscrollToError(errors, isSubmitting);

  const enableCreditLimit = getOr(true, 'enableCreditLimit', companySettings);
  const enableCreditTerms  = getOr(true, 'enableCreditTerms', companySettings);
  const currency  = getOr('USD', 'currency', companySettings);

  return (
    <StyledForm>
      <StyledTitleContainer>
        <H2 bold>Other Information</H2>
      </StyledTitleContainer>
      <StyledFormRow>
        {enableCreditLimit &&
          <CurrencyField name='creditLimit' label='Requested Credit limit' currency={currency} />
        }
        {enableCreditTerms ? (
          <>
            <SelectField
              name='creditTerms'
              label='Requested Credit Terms'
              options={[
                ...creditTermsOptions.map(
                  (option: string) => ({ label: option, value: option })
                )
              ]} />
          </>
        ) : false}
        <TextField
          type='text'
          required={requireEinFederalTaxNumber}
          name='einFederalTaxNumber'
          label='EIN / Federal Tax Number'
          placeholder='Enter EIN / Federal Tax ID Number'
        />
      </StyledFormRow>
      {customFields.map((p) => (
        <>
          <CustomField
            key={p.id}
            name={`customFields.${p.id}`}
            {...p}
            options={p.options}
            settings={companySettings}
          />
        </>
      ))}
      <FlexRowEnd>
        <StyledNextButton primary type='submit'>Next</StyledNextButton>
      </FlexRowEnd>
    </StyledForm>
  )
};
