import styled from 'styled-components';

export const StyledSelectOption = styled.div<{ focused: boolean, selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: ${props => props.focused ? '#f0f0f0' : '#fff'};

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    p {
      font-weight: ${props => props.selected ? '700' : '500'};
    }
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

  }
`

export const StyledSelectedOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const StyledSelectedOptionLabel = styled.span`
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledMultiSelectOption = styled.div<{ backgroundColor: string, textColor: string }>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
`

export const StyledMultiValueContainer = styled.div<{ backgroundColor: string, textColor: string }>`
  display: inline-flex !important;
  align-items: center;
  border-radius: 0.375rem !important;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  gap: 0.125rem;
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
`

export const StyledMultiValueLabel = styled.div<{ backgroundColor: string, textColor: string }>`
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
`

export const StyledRemoveIcon = styled.button<{ backgroundColor: string, textColor: string }>`
  position: relative;
  margin-right: -0.25rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};

  &:hover {
    filter: brightness(.90);
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`