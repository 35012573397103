import React from 'react';
import styled from 'styled-components';

import { YellowWarningIcon } from '@/components/icons';

import { HiMiniExclamationTriangle } from "react-icons/hi2";

const StyledIcon = styled(HiMiniExclamationTriangle)`
  min-width: 1.35rem;
  width: 1.35rem;
  height: 1.35rem;
  fill: ${({ theme }) => theme.color.warning};
`

interface IProps extends React.SVGAttributes<SVGElement> {
  description?: string;
}

export const WarningPopupIcon = ({ description, ...rest }: IProps) => (
  <StyledIcon title={description} {...rest} />
);